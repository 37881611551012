export const BasketModalEn = [
  {
    title: "There are no products in your basket :(",
    title2: "FREE SHIPPING ON ORDERS OVER 150 €",
    footerpay: "TOTAL",
    gobasket: "Go to My Basket",
    buy: "Buy Now",
  },
];
export const BasketModalAl = [
  {
    title: "Nuk ka produkte në shportën tuaj :(",
    title2: "TRANSPORTI I FALAS NË POROSITË MBI 150 €",
    footerpay: "TOTALI",
    gobasket: "Shko tek Shporta ime",
    buy: "Bli Tani",
  },
];
export const BasketModalMk = [
  {
    title: "Нема производи во вашата кошничка :(",
    title2: "БЕСПЛАТНА ИСПОРАКА НА НАРАЧКИ НАД 150 €",
    footerpay: "ВКУПНО",
    gobasket: "Оди во Мојата Кошничка",
    buy: "Купи Сега",
  },
];
export const BasketEn = [
  {
    title: "My Basket",
    title1: "There are no products in your basket :(",
    title2: "Similar Products",
    allpay: "TOTAL",
    button: "Continue Shopping",
    changebuttontitle: "50% Discount",
    changebuttontitle1: " Only Today!",
    buttonall: "Confirm Basket",
    form: "My Information",
    formcountry: "Country",
    formcity: "City",
    formpostcode: "ZIP Code",
    form2: "Promo Code",
    form2desc:
      "Do you have a promo code? Then, you are just a few numbers and letters away from surprise discounts!",
    forminput: "Promo Code",
    formbutton: " SHARE",
    form3: "Order Note",
  },
];
export const BasketMk = [
  {
    title: "Моја Кошничка",
    title1: "Нема производи во вашата кошничка :(",
    title2: "Слични Производи",
    allpay: "ВКУПНО",
    button: "Продолжи со Купување",
    changebuttontitle: "50% Попуст",
    changebuttontitle1: " Само Денес!",
    buttonall: "Потврди Кошничка",
    form: "Мои Информации",
    formcountry: "Земја",
    formcity: "Град",
    formpostcode: "Поштенски Број",
    form2: "Промотивен Код",
    form2desc:
      "Дали имате промотивен код? Тогаш, сте само неколку броеви и букви од изненадувачки попусти!",
    forminput: "Промотивен Код",
    formbutton: " СПОДЕЛИ",
    form3: "Забелешка за Нарачка",
  },
];
export const BasketAl = [
  {
    title: "Shporta ime",
    title1: "Nuk ka produkte në shportën tuaj :(",
    title2: "Produkte të Ngjashme",
    allpay: "TOTALI",
    button: "Vazhdo Blerjen",
    changebuttontitle: "50% Zbritje",
    changebuttontitle1: " Vetëm Sot!",
    buttonall: "Konfirmo Shportën",
    form: "Të Dhënat e Mia",
    formcountry: "Shteti",
    formcity: "Qyteti",
    formpostcode: "Kodi Postar",
    form2: "Kodi Promocional",
    form2desc:
      "Keni një kod promocional? Atëherë, jeni vetëm disa numra dhe shkronja larg nga zbritje të papritura!",
    forminput: "Kodi Promocional",
    formbutton: " NDAJE",
    form3: "Shënim për Porosinë",
  },
];
