export const LayoutDataEn = [
  {
    title: "Best Sellers",
    title2: "Trends",
    title3: "Hurry! Limited Quantity",
    categorytitle: "Accessories",
    categorydesc: "For the most stylish look",
    categorytitle2: "Fashion",
    categorydesc2: "Dress according to the latest trends",
    slider: "Best Price of the Year",
    sliderdesc: "Buy now, regret later.",
    sliderbutton: "Buy Now",
    banner: "Best Price of the Year",
    bannerdesc: "Buy now, regret later.",
    bannerbutton: "Buy Now",
    header: "Women",
    header1: "Men",
    header2: "Children",
  },
];
export const LayoutDataAl = [
  {
    title: "Produktet më të Mira",
    title2: "Trendet",
    title3: "Ngjitet! Sasia e kufizuar",
    categorytitle: "Aksesoret",
    categorydesc: "Për pamjen më elegante",
    categorytitle2: "Moda",
    categorydesc2: "Vishu sipas modës së fundit",
    slider: "Çmimi më i Mirë i Vitit",
    sliderdesc: "Bli tani, pendohu më vonë.",
    sliderbutton: "Bli Tani",
    title2desc: "Ngjitet! Sasia e kufizuar",
    banner: "Çmimi më i Mirë i Vitit",
    bannerdesc: "Bli tani, pendohu më vonë.",
    bannerbutton: "Bli Tani",
    header: "Gruaja",
    header1: "Burrë",
    header2: "Fëmijë",
  },
];
export const LayoutDataMk = [
  {
    title: "Најпродавани Производи",
    title2: "Трендови",
    title3: "Брзај! Ограничен број",
    categorytitle: "Додатоци",
    categorydesc: "За најстилски изглед",
    categorytitle2: "Мода",
    categorydesc2: "Облечете се според последните трендови",
    slider: "Најдобра Цена за Годината",
    sliderdesc: "Купи сега, жали подоцна.",
    sliderbutton: "Купи Сега",
    title2desc: "Брзај! Ограничен број",
    banner: "Најдобра Цена за Годината",
    bannerdesc: "Купи сега, жали подоцна.",
    bannerbutton: "Купи Сега",
    header: "Жени",
    header1: "Мажи",
    header2: "Деца",
  },
];
export const SubCategoryDataEn = [
  {
    title: "Women",
    subtitle: "Clothing",
    subtitle1: "Shoes",
    subtitle2: "Accessories & Bags",
    subtitle3: "Bags",
    subtitle4: "Lingerie",
    subtitle5: "Luxury & Designer",
    subtitle6: "Sport & Outdoor",

    subtitlesubname1: "Dress",
    subtitlesubname2: "T-shirt",
    subtitlesubname3: "Shirt",
    subtitlesubname4: "Jeans",
    subtitlesubname5: "Denim Jacket",
    subtitlesubname6: "Pants",
    subtitlesubname7: "Coat",
    subtitlesubname8: "Blouse",
    subtitlesubname9: "Jacket",
    subtitlesubname10: "Skirt",
    subtitlesubname11: "Sweater",
    subtitlesubname12: "Hijab Fashion",
    subtitlesubname13: "Plus Size",
    subtitlesubname14: "Trench Coat",
    subtitlesubname15: "Raincoat & Windbreaker",
    subtitlesubname16: "Sweatshirt",
    subtitlesubname17: "Coat",
    subtitlesubname18: "Cardigan",
    subtitlesubname19: "Overcoat",

    subtitle1subname1: "High Heels",
    subtitle1subname2: "Sneakers",
    subtitle1subname3: "Casual Shoes",
    subtitle1subname4: "Ballet Flats",
    subtitle1subname5: "Sandal",
    subtitle1subname6: "Boots",
    subtitle1subname7: "Winter Boots",
    subtitle1subname8: "Loafer",

    subtitle2subname1: "Bag",
    subtitle2subname2: "Watch",
    subtitle2subname3: "Jewelry",
    subtitle2subname4: "Wallet",
    subtitle2subname5: "Scarf",
    subtitle2subname6: "Hat",
    subtitle2subname7: "Gloves",
    subtitle2subname8: "Belt",
    subtitle2subname9: "Shawl",
    subtitle2subname10: "Belt",
    subtitle2subname11: "Shawl",

    subtitle3subname1: "Shoulder Bag",
    subtitle3subname2: "Backpack",
    subtitle3subname3: "Waist Bag",
    subtitle3subname4: "School Bag",
    subtitle3subname5: "Laptop Bag",
    subtitle3subname6: "Clutch",
    subtitle3subname7: "Messenger Bag",
    subtitle3subname8: "Handbag",
    subtitle3subname9: "Canvas Bag",
    subtitle3subname10: "Makeup Bag",
    subtitle3subname11: "Evening Bag",
    subtitle3subname12: "Crossbody Bag",
    subtitle3subname13: "Canvas Bag",
    subtitle3subname14: "Diaper Bag",
    subtitle3subname15: "Document Bag",
    subtitle3subname16: "Tote Bag",
    subtitle3subname17: "Feeding Bag",
    subtitle3subname18: "Cardholder",
    subtitle3subname19: "Wallet",
    subtitle3subname20: "Women's Sports Bag",

    subtitle4subname1: "Pajama Set",
    subtitle4subname2: "Nightgown",
    subtitle4subname3: "Bra",
    subtitle4subname4: "Lingerie Sets",
    subtitle4subname5: "Fantasy Clothing",
    subtitle4subname6: "Socks",
    subtitle4subname7: "Corset",
    subtitle4subname8: "Panties",
    subtitle4subname9: "Bustier",
    subtitle4subname10: "Bralette",
    subtitle4subname11: "Tank Top & Bodysuit",
    subtitle4subname12: "Jumpsuit",
    subtitle4subname13: "Garter",

    subtitle5subname1: "Luxury Bag",
    subtitle5subname2: "Luxury Clothing",
    subtitle5subname3: "Luxury Shoes",
    subtitle5subname4: "Designer Clothing",
    subtitle5subname5: "Designer Shoes",

    subtitle6subname1: "Sweatshirt",
    subtitle6subname2: "T-shirt",
    subtitle6subname3: "Sports Bra",
    subtitle6subname4: "Leggings",
    subtitle6subname5: "Tracksuit",
    subtitle6subname6: "Running Shoes",
    subtitle6subname7: "Sports Bag",
    subtitle6subname8: "Sports Equipment",
    subtitle6subname9: "Outdoor Shoes",
    subtitle6subname10: "Winter Boots",
    subtitle6subname11: "Outdoor Equipment",
    subtitle6subname12: "Sports Nutrition",
    subtitle6subname13: "Sports Accessories",
    subtitle6subname14: "Outdoor Bag",
    subtitle6subname15: "Ski Equipment",
    subtitle6subname16: "Sleeping Bag",
    subtitle6subname17: "Mat",
    subtitle6subname18: "Mountaineering",
    subtitle6subname19: "Women's Sports Jacket",
    subtitle6subname20: "Sports Shoes",
  },
  {
    title: "Men",
    subtitle: "Clothing",
    subtitle1: "Shoes",
    subtitle2: "Bags",
    subtitle3: "Plus Size",
    subtitle4: "Watches & Accessories",
    subtitle5: "Lingerie",
    subtitle6: "Sport & Outdoor",
    subtitle7: "Luxury & Designer",

    subtitlesubname1: "Shorts",
    subtitlesubname2: "T-shirt",
    subtitlesubname3: "Shirt",
    subtitlesubname4: "Tracksuit",
    subtitlesubname5: "Pants",
    subtitlesubname6: "Jacket",
    subtitlesubname7: "Jeans",
    subtitlesubname8: "Vest",
    subtitlesubname9: "Sweater",
    subtitlesubname10: "Coat",
    subtitlesubname11: "Suit",
    subtitlesubname12: "Sweatshirt",
    subtitlesubname13: "Leather Jacket",
    subtitlesubname14: "Coat",
    subtitlesubname15: "Cardigan",
    subtitlesubname16: "Trench Coat",
    subtitlesubname17: "Overcoat",
    subtitlesubname18: "Raincoat",
    subtitlesubname19: "Blazer",
    subtitlesubname20: "Polar Fleece",

    subtitle1subname1: "Sports Shoes",
    subtitle1subname2: "Casual Shoes",
    subtitle1subname3: "Walking Shoes",
    subtitle1subname4: "Cleats",
    subtitle1subname5: "Sneakers",
    subtitle1subname6: "Classic",
    subtitle1subname7: "Boots",
    subtitle1subname8: "Winter Boots",
    subtitle1subname9: "Leather Shoes",
    subtitle1subname10: "Loafer",
    subtitle1subname11: "House Slippers",
    subtitle1subname12: "Running Shoes",
    subtitle1subname13: "Boots",

    subtitle2subname1: "Backpack",
    subtitle2subname2: "Sports Bag",
    subtitle2subname3: "Laptop Bag",
    subtitle2subname4: "Suitcase & Luggage",
    subtitle2subname5: "Messenger Bag",
    subtitle2subname6: "Waist Bag",
    subtitle2subname7: "Canvas Bag",
    subtitle2subname8: "Document Bag",
    subtitle2subname9: "Wallet",

    subtitle3subname1: "Plus Size Sweatshirt",
    subtitle3subname2: "Plus Size T-shirt",
    subtitle3subname3: "Plus Size Shirt",
    subtitle3subname4: "Plus Size Pants",
    subtitle3subname5: "Plus Size Coat",
    subtitle3subname6: "Plus Size Sweater",
    subtitle3subname7: "Plus Size Cardigan",
    subtitle3subname8: "Plus Size Coat",
    subtitle3subname9: "Plus Size Sweatpants",

    subtitle4subname1: "Watch",
    subtitle4subname2: "Sunglasses",
    subtitle4subname3: "Wallet",
    subtitle4subname4: "Belt",
    subtitle4subname5: "Bag",
    subtitle4subname6: "Hat",
    subtitle4subname7: "Cardholder",
    subtitle4subname8: "Suitcase",
    subtitle4subname9: "Tie",
    subtitle4subname10: "Necktie",
    subtitle4subname11: "Scarf",
    subtitle4subname12: "Hat",
    subtitle4subname13: "Gloves",

    subtitle5subname1: "Boxers",
    subtitle5subname2: "Socks",
    subtitle5subname3: "Pajamas",
    subtitle5subname4: "Undershirt",
    subtitle5subname5: "Underwear",

    subtitle6subname1: "Tracksuit",
    subtitle6subname2: "Sports Shoes",
    subtitle6subname3: "T-shirt",
    subtitle6subname4: "Sweatshirt",
    subtitle6subname5: "Jersey",
    subtitle6subname6: "Sports Socks",
    subtitle6subname7: "Sportswear",
    subtitle6subname8: "Outdoor Shoes",
    subtitle6subname9: "Outdoor Boots",
    subtitle6subname10: "Sports Equipment",
    subtitle6subname11: "Outdoor Equipment",
    subtitle6subname12: "Sports Nutrition",
    subtitle6subname13: "Sports Accessories",
    subtitle6subname14: "Sneakers",
    subtitle6subname15: "Bicycle",
    subtitle6subname16: "Windbreaker",
    subtitle6subname17: "Mat",
    subtitle6subname18: "Mountaineering",
    subtitle6subname19: "Women's Sports Jacket",
    subtitle6subname20: "Sports Shoes",

    subtitle7subname1: "Luxury Clothing",
    subtitle7subname2: "Luxury Shoes",
    subtitle7subname3: "Luxury Bag",
  },
];
export const SubCategoryDataMk = [
  {
    title: "Жени",
    subtitle: "Облека",
    subtitle1: "Обувки",
    subtitle2: "Аксесоари и Торби",
    subtitle3: "Торби",
    subtitle4: "Долна Облека",
    subtitle5: "Лукс и Дизајн",
    subtitle6: "Спорт и Надворешна Облека",

    subtitlesubname1: "Фустан",
    subtitlesubname2: "Маица",
    subtitlesubname3: "Рубашка",
    subtitlesubname4: "Фармерки",
    subtitlesubname5: "Фармерка Јакна",
    subtitlesubname6: "Панталони",
    subtitlesubname7: "Мантил",
    subtitlesubname8: "Блуза",
    subtitlesubname9: "Јакна",
    subtitlesubname10: "Етер",
    subtitlesubname11: "Сукња",
    subtitlesubname12: "Кофта",
    subtitlesubname13: "Големи Големини",
    subtitlesubname14: "Тренчкот",
    subtitlesubname15: "Кишобран и Ветровка",
    subtitlesubname16: "Светофер",
    subtitlesubname17: "Капут",
    subtitlesubname18: "Хирка",
    subtitlesubname19: "Палто",

    subtitle1subname1: "Токани Обувки",
    subtitle1subname2: "Сникерси",
    subtitle1subname3: "Секојдневни Обувки",
    subtitle1subname4: "Балетанки",
    subtitle1subname5: "Сандали",
    subtitle1subname6: "Чизми",
    subtitle1subname7: "Боти",
    subtitle1subname8: "Зимски Чизми",
    subtitle1subname9: "Лоаферки",

    subtitle2subname1: "Торба",
    subtitle2subname2: "Саат",
    subtitle2subname3: "Накит",
    subtitle2subname4: "Чевли",
    subtitle2subname5: "Шал",
    subtitle2subname6: "Капа",
    subtitle2subname7: "Ракавици",
    subtitle2subname8: "Каиш",
    subtitle2subname9: "Шал",
    subtitle2subname10: "Каиш",
    subtitle2subname11: "Шал",

    subtitle3subname1: "Рамна Торба",
    subtitle3subname2: "Ранец",
    subtitle3subname3: "Коланска Торба",
    subtitle3subname4: "Раница за Училиште",
    subtitle3subname5: "Торба за Лаптоп",
    subtitle3subname6: "Портфеј",
    subtitle3subname7: "Поштарска Торба",
    subtitle3subname8: "Рачна Торба",
    subtitle3subname9: "Платнена Торба",
    subtitle3subname10: "Чанта за Шминка",
    subtitle3subname11: "Вечерна Торба",
    subtitle3subname12: "Крстарка Торба",
    subtitle3subname13: "Платнена Торба",
    subtitle3subname14: "Торба за Мајка и Бебе",
    subtitle3subname15: "Документ Торба",
    subtitle3subname16: "Торба за Заверки",
    subtitle3subname17: "Торба за Белезници",
    subtitle3subname18: "Детска Торба",
    subtitle3subname19: "Картичник",
    subtitle3subname20: "Торба за Жени Спорт",

    subtitle4subname1: "Пижама Комплет",
    subtitle4subname2: "Ноќница",
    subtitle4subname3: "Сутиен",
    subtitle4subname4: "Комплети за Дома",
    subtitle4subname5: "Фантазиска Облека",
    subtitle4subname6: "Чорапи",
    subtitle4subname7: "Корсет",
    subtitle4subname8: "Колан",
    subtitle4subname9: "Бустие",
    subtitle4subname10: "Бралет",
    subtitle4subname11: "Маица и Боди",
    subtitle4subname12: "Комбинезон",
    subtitle4subname13: "Јартекс",

    subtitle5subname1: "Лукс Чанта",
    subtitle5subname2: "Лукс Облека",
    subtitle5subname3: "Лукс Чевли",
    subtitle5subname4: "Дизајнерска Облека",
    subtitle5subname5: "Дизајнерски Чевли",

    subtitle6subname1: "Светофер",
    subtitle6subname2: "Маица",
    subtitle6subname3: "Спортска Блуза",
    subtitle6subname4: "Тајтс",
    subtitle6subname5: "Еркан",
    subtitle6subname6: "Трчање Чевли",
    subtitle6subname7: "Спортска Торба",
    subtitle6subname8: "Спортска Опрема",
    subtitle6subname9: "Надворешни Чевли",
    subtitle6subname10: "Зимски Чевли",
    subtitle6subname11: "Надворешна Опрема",
    subtitle6subname12: "Исхрана за Спортисти",
    subtitle6subname13: "Аксесоари за Спортисти",
    subtitle6subname14: "Надворешна Торба",
    subtitle6subname15: "Ски Опрема",
    subtitle6subname16: "Спиење Чулка",
    subtitle6subname17: "Јога Мат",
    subtitle6subname18: "Планинарење",
    subtitle6subname19: "Спортска Јакна за Жени",
    subtitle6subname20: "Спортски Чевли за Жени",
  },

  {
    title: "Маж",
    subtitle: "Облека",
    subtitle1: "Обувки",
    subtitle2: "Чанти",
    subtitle3: "Големи Големини",
    subtitle4: "Часовници и Додатоци",
    subtitle5: "Долна Облека",
    subtitle6: "Спорт и Надворешна Облека",
    subtitle7: "Лукс и Дизајн",

    subtitlesubname1: "Шорцеви",
    subtitlesubname2: "Маица",
    subtitlesubname3: "Кошула",
    subtitlesubname4: "Тренирка",
    subtitlesubname5: "Панталони",
    subtitlesubname6: "Јакна",
    subtitlesubname7: "Фармерки",
    subtitlesubname8: "Јакета",
    subtitlesubname9: "Кардиган",
    subtitlesubname10: "Мантил",
    subtitlesubname11: "Костум",
    subtitlesubname12: "Светофер",
    subtitlesubname13: "Кожен Јакен",
    subtitlesubname14: "Кабана",
    subtitlesubname15: "Хирка",
    subtitlesubname16: "Тренчкот",
    subtitlesubname17: "Палто",
    subtitlesubname18: "Должник",
    subtitlesubname19: "Блејзер",
    subtitlesubname20: "Полар",

    subtitle1subname1: "Спортски Обувки",
    subtitle1subname2: "Секојдневни Обувки",
    subtitle1subname3: "Обувки за Шетање",
    subtitle1subname4: "Крампони",
    subtitle1subname5: "Сникерси",
    subtitle1subname6: "Класични Чевли",
    subtitle1subname7: "Чизми",
    subtitle1subname8: "Зимски Чизми",
    subtitle1subname9: "Кожни Чевли",
    subtitle1subname10: "Лоаферки",
    subtitle1subname11: "Папучи",
    subtitle1subname12: "Трчање Чевли",
    subtitle1subname13: "Чизми",

    subtitle2subname1: "Ранец",
    subtitle2subname2: "Спортска Торба",
    subtitle2subname3: "Торба за Лаптоп",
    subtitle2subname4: "Валтеж и Весници",
    subtitle2subname5: "Поштарска Торба",
    subtitle2subname6: "Коланска Торба",
    subtitle2subname7: "Платнена Торба",
    subtitle2subname8: "Торба за Документи",
    subtitle2subname9: "Чамка",

    subtitle3subname1: "Големи Големини Светофер",
    subtitle3subname2: "Големи Големини Маица",
    subtitle3subname3: "Големи Големини Кошула",
    subtitle3subname4: "Големи Големини Панталони",
    subtitle3subname5: "Големи Големини Мантил",
    subtitle3subname6: "Големи Големини Јакна",
    subtitle3subname7: "Големи Големини Кардиган",
    subtitle3subname8: "Големи Големини Кабана",
    subtitle3subname9: "Големи Големини Тренирка",

    subtitle4subname1: "Часовник",
    subtitle4subname2: "Слънчеви Очила",
    subtitle4subname3: "Кожен Кош",
    subtitle4subname4: "Колан",
    subtitle4subname5: "Торба",
    subtitle4subname6: "Шапка",
    subtitle4subname7: "Кард Холдер",
    subtitle4subname8: "Валтеж",
    subtitle4subname9: "Кравата",
    subtitle4subname10: "Грло",
    subtitle4subname11: "Шал",
    subtitle4subname12: "Капа",
    subtitle4subname13: "Ръкавици",

    subtitle5subname1: "Боксерки",
    subtitle5subname2: "Чорапи",
    subtitle5subname3: "Пижама",
    subtitle5subname4: "Майка",
    subtitle5subname5: "Бельо",

    subtitle6subname1: "Тренирка",
    subtitle6subname2: "Спортски Обувки",
    subtitle6subname3: "Маица",
    subtitle6subname4: "Суитшърт",
    subtitle6subname5: "Форма",
    subtitle6subname6: "Спортни Чорапи",
    subtitle6subname7: "Спортно Облекло",
    subtitle6subname8: "Надворешни Обувки",
    subtitle6subname9: "Надворешни Чизми",
    subtitle6subname10: "Спортни Снаряди",
    subtitle6subname11: "Надворешни Снаряди",
    subtitle6subname12: "Хранителни Добавки",
    subtitle6subname13: "Аксесоари за Спортисти",
    subtitle6subname14: "Сникерси",
    subtitle6subname15: "Велосипед",
    subtitle6subname16: "Ветровка",
    subtitle6subname17: "Мат",
    subtitle6subname18: "Планинарење",
    subtitle6subname19: "Спортска Јакна за Жени",
    subtitle6subname20: "Спортски Чевли за Жени",

    subtitle7subname1: "Луксузна Облека",
    subtitle7subname2: "Луксузни Обувки",
    subtitle7subname3: "Луксузни Чанти",
  },
];
export const SubCategoryDataAl = [
  {
    title: "Gruaja",
    subtitle: "Veshje",
    subtitle1: "Këpucë",
    subtitle2: "Aksesore & Çanta",
    subtitle3: "Çantë",
    subtitle4: "Intimo",
    subtitle5: "Luks & Dizajn",
    subtitle6: "Sport & Jashtë",

    subtitlesubname1: "Fustan",
    subtitlesubname2: "Bluzë",
    subtitlesubname3: "Këmishë",
    subtitlesubname4: "Pantollonat e Jeans",
    subtitlesubname5: "Xhaketa e Jeans",
    subtitlesubname6: "Pantollona",
    subtitlesubname7: "Mont",
    subtitlesubname8: "Bluzë",
    subtitlesubname9: "Xhaketë",
    subtitlesubname10: "Fustan",
    subtitlesubname11: "Jumper",
    subtitlesubname12: "Veshje për të mbuluar",
    subtitlesubname13: "Madhësia e Madhe",
    subtitlesubname14: "Trençkot",
    subtitlesubname15: "Palët & Vrapues",
    subtitlesubname16: "Bluzë me kapuç",
    subtitlesubname17: "Xhaketa",
    subtitlesubname18: "Hoodie",
    subtitlesubname19: "Palto",

    subtitle1subname1: "Këpucë me taka",
    subtitle1subname2: "Sneaker",
    subtitle1subname3: "Këpucë e përditshme",
    subtitle1subname4: "Babet",
    subtitle1subname5: "Sandale",
    subtitle1subname6: "Çizme",
    subtitle1subname7: "Çizme Dimri",
    subtitle1subname8: "Loafer",

    subtitle2subname1: "Çantë",
    subtitle2subname2: "Orë",
    subtitle2subname3: "Bizhuteri",
    subtitle2subname4: "Portofol",
    subtitle2subname5: "Shall",
    subtitle2subname6: "Kapele",
    subtitle2subname7: "Doreza",
    subtitle2subname8: "Këmishë",
    subtitle2subname9: "Shall",
    subtitle2subname10: "Rrip",
    subtitle2subname11: "Shall",

    subtitle3subname1: "Çantë mbi Shpatull",
    subtitle3subname2: "Çantë në Kurriz",
    subtitle3subname3: "Çantë beli",
    subtitle3subname4: "Çantë shkolle",
    subtitle3subname5: "Çantë laptopi",
    subtitle3subname6: "Portofol",
    subtitle3subname7: "Çanta postare",
    subtitle3subname8: "Çantë dorë",
    subtitle3subname9: "Çantë kanavace",
    subtitle3subname10: "Çantë grimcë",
    subtitle3subname11: "Çanta elegante",
    subtitle3subname12: "Çanta kros",
    subtitle3subname13: "Çantë me bel",
    subtitle3subname14: "Çanta në gjoks për nënë dhe foshnjë",
    subtitle3subname15: "Çanta dokumentesh",
    subtitle3subname16: "Çanta tote",
    subtitle3subname17: "Çanta ushqimi",
    subtitle3subname18: "Titullar kartash",
    subtitle3subname19: "Portofol",
    subtitle3subname20: "Çantë sportive për gra",

    subtitle4subname1: "Pizhamë",
    subtitle4subname2: "Shal",
    subtitle4subname3: "Bluzë gjumi",
    subtitle4subname4: "Set lingerie",
    subtitle4subname5: "Veshje erotike",
    subtitle4subname6: "Çorape",
    subtitle4subname7: "Korset",
    subtitle4subname8: "Underwear",
    subtitle4subname9: "Bustiere",
    subtitle4subname10: "Braletë",
    subtitle4subname11: "Bluzë dhe body",
    subtitle4subname12: "Kombinezon",
    subtitle4subname13: "Jartiyer",

    subtitle5subname1: "Çantë luksoze",
    subtitle5subname2: "Veshje luksoze",
    subtitle5subname3: "Këpucë luksoze",
    subtitle5subname4: "Veshje e dizajnuar",
    subtitle5subname5: "Këpucë e dizajnuar",

    subtitle6subname1: "Bluzë me kapuç",
    subtitle6subname2: "Bluzë",
    subtitle6subname3: "Soutien sportiv",
    subtitle6subname4: "Tayt",
    subtitle6subname5: "Trening",
    subtitle6subname6: "Këpucë për vrapim",
    subtitle6subname7: "Çantë sportive",
    subtitle6subname8: "Pajisje sportive",
    subtitle6subname9: "Këpucë për jashtë",
    subtitle6subname10: "Çizme Dimri",
    subtitle6subname11: "Pajisje për jashtë",
    subtitle6subname12: "Ushqime sportive",
    subtitle6subname13: "Aksesore sportive",
    subtitle6subname14: "Çantë për jashtë",
    subtitle6subname15: "Materiale skijimi",
    subtitle6subname16: "Tulum gjumi",
    subtitle6subname17: "Tapetë",
    subtitle6subname18: "Ngjitur",
    subtitle6subname19: "Jakë sportive për gra",
    subtitle6subname20: "Këpucë sportive për gra",
  },

  {
    title: "Burrë",
    subtitle: "Veshje",
    subtitle1: "Këpucë",
    subtitle2: "Çantë",
    subtitle3: "Madhësia e Madhe",
    subtitle4: "Ora & Aksesore",
    subtitle5: "Intimo",
    subtitle6: "Sport & Jashtë",
    subtitle7: "Luks & Dizajner",

    subtitlesubname1: "Sort",
    subtitlesubname2: "T-shirt",
    subtitlesubname3: "Këmishë",
    subtitlesubname4: "Trening",
    subtitlesubname5: "Pantallona",
    subtitlesubname6: "Xhakete",
    subtitlesubname7: "Pantallona e Jeans",
    subtitlesubname8: "Xhakete",
    subtitlesubname9: "Xhup",
    subtitlesubname10: "Mont",
    subtitlesubname11: "Kostum",
    subtitlesubname12: "Bluzë me kapuç",
    subtitlesubname13: "Xhupa lëkure",
    subtitlesubname14: "Këpucë",
    subtitlesubname15: "Holl",
    subtitlesubname16: "Trençkot",
    subtitlesubname17: "Palto",
    subtitlesubname18: "Mëngjes",
    subtitlesubname19: "Blazer",
    subtitlesubname20: "Polar",

    subtitle1subname1: "Këpucë sportive",
    subtitle1subname2: "Këpucë e përditshme",
    subtitle1subname3: "Këpucë për ecje",
    subtitle1subname4: "Krampona",
    subtitle1subname5: "Sneaker",
    subtitle1subname6: "Klasike",
    subtitle1subname7: "Çizme",
    subtitle1subname8: "Çizme Dimri",
    subtitle1subname9: "Këpucë lëkure",
    subtitle1subname10: "Loafer",
    subtitle1subname11: "Papuçe shtëpie",
    subtitle1subname12: "Këpucë për vrapim",
    subtitle1subname13: "Çizme",

    subtitle2subname1: "Çantë në kurriz",
    subtitle2subname2: "Çanta sportive",
    subtitle2subname3: "Çanta laptopi",
    subtitle2subname4: "Valixhe & Çantë udhëtimi",
    subtitle2subname5: "Çanta postare",
    subtitle2subname6: "Çantë beli",
    subtitle2subname7: "Çantë me leckë",
    subtitle2subname8: "Çantë dokumentesh",
    subtitle2subname9: "Portofol",

    subtitle3subname1: "Bluza e Madhe e Sweatshirt",
    subtitle3subname2: "Bluza e Madhe e T-shirt",
    subtitle3subname3: "Bluza e Madhe e Këmishës",
    subtitle3subname4: "Bluza e Madhe e Pantallonave",
    subtitle3subname5: "Bluza e Madhe e Montit",
    subtitle3subname6: "Bluza e Madhe e Xhupit",
    subtitle3subname7: "Bluza e Madhe e Harkës",
    subtitle3subname8: "Bluza e Madhe e Kabamës",
    subtitle3subname9: "Bluza e Madhe e Nënveshjes së Treningut",

    subtitle4subname1: "Ora",
    subtitle4subname2: "Syze dielli",
    subtitle4subname3: "Portofol",
    subtitle4subname4: "Rrip",
    subtitle4subname5: "Çanta",
    subtitle4subname6: "Kapelë",
    subtitle4subname7: "Titull kartash",
    subtitle4subname8: "Valixhe",
    subtitle4subname9: "Kravatë",
    subtitle4subname10: "Qafëzak",
    subtitle4subname11: "Shall",
    subtitle4subname12: "Kapele",
    subtitle4subname13: "Doreza",

    subtitle5subname1: "Boxer",
    subtitle5subname2: "Çorap",
    subtitle5subname3: "Pizhamë",
    subtitle5subname4: "Bluzë dhe thënie",
    subtitle5subname5: "Rroba Brendshme",

    subtitle6subname1: "Trening",
    subtitle6subname2: "Këpucë sportive",
    subtitle6subname3: "T-shirt",
    subtitle6subname4: "Bluza me kapuç",
    subtitle6subname5: "Uniformë",
    subtitle6subname6: "Çorap sportive",
    subtitle6subname7: "Rroba sportive",
    subtitle6subname8: "Këpucë për jashtë",
    subtitle6subname9: "Çizme për jashtë",
    subtitle6subname10: "Pajisje sportive",
    subtitle6subname11: "Pajisje për jashtë",
    subtitle6subname12: "Ushqime sportive",
    subtitle6subname13: "Aksesore sportive",
    subtitle6subname14: "Sneaker",
    subtitle6subname15: "Bicikletë",
    subtitle6subname16: "Rroba kundër erës",
    subtitle6subname17: "Tapetë",
    subtitle6subname18: "Alpinizëm",
    subtitle6subname19: "Xhakë sportive për gra",
    subtitle6subname20: "Këpucë sportive për gra",

    subtitle7subname1: "Veshje luksoze",
    subtitle7subname2: "Këpucë luksoze",
    subtitle7subname3: "Çantë luksoze",
  },
];
