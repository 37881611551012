export const UserDataEn = [
  {
    title: "My Account",
    title1: "My Account Information",
    title2: "My Addresses",
    title3: "My Favorites",
    title4: "All My Orders",
    title5: "My Profile Information",
    title6: "Full Name",
    title7: "E-mail",
    title8: "Edit",
    title9: "Edit My Account Information",
    title10: "Name",
    button: "Cancel",
    title11: "Edit",
    title12: "Update Password",
    title13: "Current Password",
    title14: "New Password",
    title15:
      "Your password must be <b>at least 7 characters</b> and <b>up to 64 characters</b>, and it should contain both letters and numbers.",
    title16: "Confirm New Password",
    title17: " Update",
    address: "My Addresses",
    address1: "Add Address",
    address2: "Edit",
    address3: "Add / Edit Address",
    address4: "Address Title",
    address5: "Full Name",
    address6: "Phone Number",
    address7: "Address",
    address8: "City",
    address9: "District",
    address10: "Neighborhood",
    address11: "Address Description",
    address12: "Postal Code:",
    address13: "Save as selected address",
    address14: " Cancel:",
    address15: "Save",
    fav: "My Favorites",
    product: "My Orders",
    product1: "All Orders",
    product2: "Last 1 Month",
    product3: "Last 3 Months",
    product4: "Last 1 Year",
  },
];
export const UserDataMk = [
  {
    title: "Мој профил",
    title1: "Информации за мојот профил",
    title2: "Моите адреси",
    title3: "Мои омилени",
    title4: "Сите мои нарачки",
    title5: "Информации за мојот профил",
    title6: "Име и Презиме",
    title7: "E-mail",
    title8: "Уреди",
    title9: "Уреди ги информациите за мојот профил",
    title10: "Име",
    button: "Откажи",
    title11: "Уреди",
    title12: "Ажурирање на Лозинка",
    title13: "Моментална Лозинка",
    title14: "Нова Лозинка",
    title15:
      "Вашата лозинка мора да биде <b>најмалку 7 знаци</b> и <b>најмногу 64 знаци</b>, и треба да содржи букви и броеви.",
    title16: "Потврди Ја Новата Лозинка",
    title17: " Ажурирај",
    address: "Моите Адреси",
    address1: "Додај Адреса",
    address2: "Уреди",
    address3: "Додај / Уреди Адреса",
    address4: "Наслов на Адресата",
    address5: "Име и Презиме",
    address6: "Телефонски Број",
    address7: "Адреса",
    address8: "Град",
    address9: "Општина",
    address10: "Маало",
    address11: "Опис на Адресата",
    address12: "Поштенски Код:",
    address13: "Зачувај како избрана адреса",
    address14: " Откажи:",
    address15: "Зачувај",
    fav: "Мои Омилени",
    product: "Мои Нарачки",
    product1: "Сите Нарачки",
    product2: "Последни 1 Месец",
    product3: "Последни 3 Месеци",
    product4: "Последна 1 Година",
  },
];
export const UserDataAl = [
  {
    title: "Llogaria ime",
    title1: "Informacioni i llogarisë sime",
    title2: "Adresat e mia",
    title3: "Të preferuarat e mia",
    title4: "Të gjitha porositë e mia",
    title5: "Informacioni i profilin tim",
    title6: "Emri dhe Mbiemri",
    title7: "E-mail",
    title8: "Ndrysho",
    title9: "Ndrysho informacionin e llogarisë sime",
    title10: "Emri",
    button: "Anulo",
    title11: "Ndrysho",
    title12: "Përditëso fjalëkalimin",
    title13: "Fjalëkalimi aktual",
    title14: "Fjalëkalim i ri",
    title15:
      "Fjalëkalimi juaj duhet të jetë <b>së paku 7 karaktere</b> dhe <b>deri në 64 karaktere</b>, dhe duhet të përmbajë edhe shkronja dhe numra.",
    title16: "Përditëso Fjalëkalimin e Ri",
    title17: " Përditëso",
    address: "Adresat e mia",
    address1: "Shto Adresë",
    address2: "Ndrysho",
    address3: "Shto / Ndrysho Adresë",
    address4: "Titulli i Adresës",
    address5: "Emri dhe Mbiemri",
    address6: "Numri i Telefonit",
    address7: "Adresa",
    address8: "Qyteti",
    address9: "Komuna",
    address10: "Lagja",
    address11: "Përshkrimi i Adresës",
    address12: "Kodi Postar:",
    address13: "Ruaj si adresë e zgjedhur",
    address14: " Anulo:",
    address15: "Ruaj",
    fav: "Të preferuarat e mia",
    product: "Porositë e mia",
    product1: "Të gjitha Porositë",
    product2: "Muaji i fundit",
    product3: "Tre muajt e fundit",
    product4: "Viti i fundit",
  },
];
