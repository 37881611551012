export const FooterDataAl = [
  {
    name: "Rreth Nesh",
    mail: "E-mail",
    time: "Orët e Hapjes",
    footer: "Të gjitha të drejtat e rezervuara.",
  },
  {
    name: "Informacioni Ynë",
    title: [
      {
        subname: "Rreth Nesh",
      },
      {
        subname: "Kontakti",
      },
      {
        subname: "Kushtet dhe Kushtet e Përdorimit",
      },
      {
        subname: "Kthimet dhe Ndryshimet",
      },
      {
        subname: "Transporti dhe Dorëzimi",
      },
    ],
  },
  {
    name: "Llogaria Ime",
    title: [
      {
        subname: "Llogaria Ime",
      },
      {
        subname: "Shporta Ime",
      },
      {
        subname: "Të Preferuarat e Mia",
      },
      {
        subname: "Statusi i Porosisë",
      },
      {
        subname: "Gjurmimi i Porosisë",
      },
    ],
  },
  {
    name: "Korporative",
    title: [
      {
        subname: "Rreth Nesh",
      },
      {
        subname: "Pyetje të Shpeshta",
      },
      {
        subname: "Kontakti",
      },
    ],
  },
];

export const FooterDataEn = [
  {
    name: "About Us",
    mail: "E-mail",
    time: "Opening Hours",
    footer: "All rights reserved.",
  },
  {
    name: "Our information",
    title: [
      {
        subname: "About Us",
      },
      {
        subname: "Contact",
      },
      {
        subname: "Terms and Conditions",
      },
      {
        subname: "Returns and Exchanges",
      },
      {
        subname: "Shipping and Delivery",
      },
    ],
  },
  {
    name: "My Account",
    title: [
      {
        subname: "My Account",
      },
      {
        subname: "My Cart",
      },
      {
        subname: "My Favorites",
      },
      {
        subname: "Order Status",
      },
      {
        subname: "Order Tracking",
      },
    ],
  },
  {
    name: "Corporate",
    title: [
      {
        subname: "About Us",
      },
      {
        subname: "FAQs",
      },
      {
        subname: "Contact",
      },
    ],
  },
];
export const FooterDataMk = [
  {
    name: "За Нас",
    mail: "Е-пошта",
    time: "Работно Време",
    footer: "Сите права задржани.",
  },
  {
    name: "Наши Информации",
    title: [
      {
        subname: "За Нас",
      },
      {
        subname: "Контакт",
      },
      {
        subname: "Услови и Правила",
      },
      {
        subname: "Враќање и Замена",
      },
      {
        subname: "Испорака и Достава",
      },
    ],
  },
  {
    name: "Моја Сметка",
    title: [
      {
        subname: "Моја Сметка",
      },
      {
        subname: "Моја Кошничка",
      },
      {
        subname: "Мои Омилени",
      },
      {
        subname: "Статус на Нарачката",
      },
      {
        subname: "Пратење на Нарачката",
      },
    ],
  },
  {
    name: "Корпоративни",
    title: [
      {
        subname: "За Нас",
      },
      {
        subname: "Често Поставувани Проекти",
      },
      {
        subname: "Контакт",
      },
    ],
  },
];
