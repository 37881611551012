export const LoginModalEn = [
  {
    title: "Log In",
    title1: "Sign Up",
    title2: "Quick Login",
    button: "Log In",
    detail: "My Information",
    detailmain: "My Account",
  },
];
export const LoginModalMk = [
  {
    title: "Најави се",
    title1: "Регистрирај се",
    title2: "Брза најава",
    button: "Најави се",
    detail: "Моите Информации",
    detailmain: "Мојата Сметка",
  },
];
export const LoginModalAl = [
  {
    title: "Hyrje",
    title1: "Regjistrohu",
    title2: "Hyrje e Shpejtë",
    button: "Hyrje",
    detail: "Informacioni Im",
    detailmain: "Llogaria Ime",
  },
];
export const LoginEn = [
  {
    tabbar: "Home",
    tabbar2: "Log In",
    title: "Log In",
    desc: "Log in now to access your favorite products, address book, communication preferences, and take advantage of our quick payment feature.",
    title1: "E-Mail",
    title2: "Password",
    check: "Remember Me",
    pass: "Forgot Password",
    button: "Log In",
    subdesc: "Don't have an account? Sign up now.",
  },
];

export const LoginAl = [
  {
    tabbar: "Faqja Kryesore",
    tabbar2: "Hyrje",
    title: "Hyrje",
    desc: "Hyni tani për të patur qasje në produktet tuaja të preferuara, librin tuaj të adresave, dhe preferencat e komunikimit, dhe për të përfituar nga veçoritë tona të pagesës së shpejtë.",
    title1: "E-Posta",
    title2: "Fjalëkalimi",
    check: "Mbaj Mend",
    pass: "Kam Harruar Fjalëkalimin",
    button: "Hyrje",
    subdesc: "Nuk keni një llogari? Regjistrohuni tani.",
  },
];
export const LoginMk = [
  {
    tabbar: "Почетна",
    tabbar2: "Најави се",
    title: "Најави се",
    desc: "Најавете сега за да имате пристап до вашите омилени производи, адресарот, и постигнување од нашата опција за брзо плаќање.",
    title1: "Е-пошта",
    title2: "Лозинка",
    check: "Запамти ме",
    pass: "Ја заборавив лозинката",
    button: "Најави се",
    subdesc: "Немате сметка? Регистрирајте се сега.",
  },
];
export const SigninEn = [
  {
    tabbar: "Home",
    tabbar2: "Sign Up",
    title: "Sign Up",
    desc: "Sign up now to access your favorite products, address book, communication preferences, and take advantage of our quick payment feature.",
    title1: "Your Name",
    title2: "Your Email Address",
    title3: "Password",
    title4: "Confirm Password",
    check:
      "By saving your information, you agree to our Terms and Conditions and Cookie Policies.",
    button: "Sign Up",
  },
];
export const SigninMk = [
  {
    tabbar: "Почетна",
    tabbar2: "Регистрирај се",
    title: "Регистрирај се",
    desc: "Регистрирајте се сега за да имате пристап до вашите омилени производи, адресарот, и постигнување од нашата опција за брзо плаќање.",
    title1: "Вашето Име",
    title2: "Вашата Е-пошта",
    title3: "Лозинка",
    title4: "Потврдете Лозинка",
    check:
      "Со зачувување на вашите информации, се согласувате со нашите Услови и Политиките за Колачиња.",
    button: "Регистрирај се",
  },
];
export const SigninAl = [
  {
    tabbar: "Faqja Kryesore",
    tabbar2: "Regjistrohu",
    title: "Regjistrohu",
    desc: "Regjistrohu tani për të pasur qasje në produktet e tua të preferuara, librin e adresave, dhe për të përfituar nga veçoritë tona të pagesës së shpejtë.",
    title1: "Emri Juaj",
    title2: "Adresa Juaj E-Mail",
    title3: "Fjalëkalimi Juaj",
    title4: "Konfirmo Fjalëkalimin",
    check:
      "Duke ruajtur informacionin tuaj, pajtoheni me Kushtet dhe Kushtet e Përdorimit dhe Politikat e Cookies.",
    button: "Regjistrohu",
  },
];
export const PassEn = [
  {
    tabbar: "Home",
    tabbar2: "Reset Password",
    desc: "We need your email address to send the password reset link.",
    button: "Go Back to Previous Page",
    detail: "Your Password has been Sent",
    detaildesc: "Your password reset link has been sent to your email address.",
    detailtitle: "If you haven't received the Password Reset Email:",
    detaillist:
      "It may take up to 10 minutes to receive the password reset email.",
    detaillist1: "Check your Spam and other email folders.",
    detaillist2: "Verify the accuracy of the entered email address.",
    detailbutton: " Log In",
    detailbutton1: "Resend",
  },
];
export const PassMk = [
  {
    tabbar: "Дома",
    tabbar2: "Ресетирање на Лозинка",
    desc: "Ни е потребна вашата е-пошта за испраќање на линк за ресетирање на лозинка.",
    button: "Врати се на Претходната Страница",
    detail: "Вашата Лозинка е Испратена",
    detaildesc:
      "Линкот за ресетирање на лозинката е испратен на вашата е-пошта.",
    detailtitle: "Ако не сте добиле Е-пошта за Ресетирање на Лозинката:",
    detaillist:
      "Може да помине до 10 минути за примање на е-поштата за ресетирање на лозинката.",
    detaillist1: "Проверете го вашиот Spam и другите фасцикли за е-пошта.",
    detaillist2: "Потврдете ја точноста на внесената е-пошта.",
    detailbutton: "Најави се",
    detailbutton1: "Повторно испрати",
  },
];
export const PassAl = [
  {
    tabbar: "Kreu",
    tabbar2: "Rivendos Fjalëkalimin",
    desc: "Kemi nevojë për adresën tuaj të emailit për të dërguar lidhjen e rivendosjes së fjalëkalimit.",
    button: "Kthehu tek Faqja e Mëparshme",
    detail: "Fjalëkalimi Juaj është Dërguar",
    detaildesc:
      "Lidhja për rivendosjen e fjalëkalimit është dërguar në adresën tuaj të emailit.",
    detailtitle: "Nëse nuk keni marrë Emailin për Rivendosjen e Fjalëkalimit:",
    detaillist:
      "Mund të duhet deri në 10 minuta për të marrë emailin për rivendosjen e fjalëkalimit.",
    detaillist1:
      "Kontrolloni folderin tuaj Spam dhe folderët e tjerë të emailit.",
    detaillist2: "Verifikoni saktësinë e adresës së emailit të futur.",
    detailbutton: "Hyrje",
    detailbutton1: "Dërgo Përsëri",
  },
];
