import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useContext, lazy, Suspense } from "react";
// import ProductDetailPage from "../pages/ProductDetailPage";

//? Pages

const HomePage = lazy(() => import("../pages/HomePage"));
const MyInfosPage = lazy(() => import("../pages/MyInfosPage"));
const ProductDetailPage = lazy(() => import("../pages/ProductDetailPage"));
const MyAccountPage = lazy(() =>
  import("../pages/MyAccountPages/MyAccountPage")
);
const CategoriesPage = lazy(() => import("../pages/CategoriesPage"));
const BasketPage = lazy(() => import("../pages/BasketPage"));
const WishListPage = lazy(() => import("../pages/WishListPage"));
// Auth
const Login = lazy(() => import("../pages/auth/Login"));
const Register = lazy(() => import("../pages/auth/Register"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));

const pages = [
  //? Pages
  { path: "/", element: <HomePage /> },
  { path: "/my-infos", element: <MyInfosPage /> },
  { path: "/product-details/:productSlug", element: <ProductDetailPage /> },
  { path: "/my-account", element: <MyAccountPage /> },
  { path: "/categories", element: <CategoriesPage /> },
  { path: "/categories/:categorySlug", element: <CategoriesPage /> },
  {
    path: "/categories/:categorySlug/:categorynameslug",
    element: <CategoriesPage />,
  },
  { path: "/basket", element: <BasketPage /> },
  { path: "/wishlist", element: <WishListPage /> },
  // Auth
  { path: "/login", element: <Login /> },
  { path: "/register", element: <Register /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "*", element: <h1>not found</h1> },
];

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        {pages.map((page, index) => {
          return (
            <Route
              key={index}
              path={page.path}
              element={<Suspense>{page.element}</Suspense>}
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
