export const CategoryDataEn = [
  {
    tabbar: "Home",
    tabbar2: "Categories",
    sortelement: "Product",
    sortelement1: "Sort",
    sortelement2: "Best Sellers",
    sortelement3: "By Price",
    sortelement4: "By Rating",
    sortelementend: "Show",
    filtertitle: "Category",
    filtertitle1: "Color",
    filtertitle2: "Size",
    filtertitle3: "Brand",
    filtertitle4: "Price",
    filterbutton: "Search",
  },
];
export const CategoryDataMk = [
  {
    tabbar: "Почетна",
    tabbar2: "Категории",
    sortelement: "Производ",
    sortelement1: "Сортирај",
    sortelement2: "Најпродавани",
    sortelement3: "Според Цена",
    sortelement4: "Според Оцена",
    sortelementend: "Прикажи",
    filtertitle: "Категорија",
    filtertitle1: "Боја",
    filtertitle2: "Големина",
    filtertitle3: "Бренд",
    filtertitle4: "Цена",
    filterbutton: "Пребарај",
  },
];
export const CategoryDataAl = [
  {
    tabbar: "Faqja Kryesore",
    tabbar2: "Kategoritë",
    sortelement: "Produkti",
    sortelement1: "Rendit",
    sortelement2: "Më Shumë shitje",
    sortelement3: "Sipas Çmimit",
    sortelement4: "Sipas Vlerësimit",
    sortelementend: "Shfaq",
    filtertitle: "Kategoria",
    filtertitle1: "Ngjyra",
    filtertitle2: "Madhësia",
    filtertitle3: "Marka",
    filtertitle4: "Çmimi",
    filterbutton: "Kërko",
  },
];
