export const FooterTabDataEn = [
  {
    title: "Extra Fast Delivery",
    desc: "Your order will be delivered within 3-5 business days after all your items are ready.",
    title1: "Best Prices",
    desc1: "We will bring you the most affordable products instantly.",
    title2: "Unlimited Guarantee",
    desc2:
      "If you are not satisfied with your product, you can return it anytime.",
    titlemain: "JOIN US",
    titlemaindesc: "Stay informed about campaigns and surprises",
    bottomtitle:
      "This site is protected by Strend and is subject to Google's Privacy Policy and Terms of Service.",
    mainbutton: "JOIN US!",
  },
];

export const FooterTabDataAl = [
  {
    title: "Dërgesë Ekstra Shpejt",
    desc: "Porosia juaj do të dorëzohet brenda 3-5 ditëve të punës pasi të gjitha produktet tuaja janë gati.",
    title1: "Çmimet më të Mirat",
    desc1: "Ju do t'ju sjellim produktet më të arsyeshme menjëherë.",
    title2: "Garanci pa Kufi",
    desc2:
      "Nëse nuk jeni të kënaqur me produktin tuaj, mund ta kthehni në çdo kohë.",
    titlemain: "BASHKOHU ME NE",
    titlemaindesc: "Mbani informacione për kampanjat dhe surprizat",
    bottomtitle:
      "Kjo faqe mbrohet nga Strend dhe është nënshtruar Politikës së Privatësisë dhe Kushteve të Shërbimit të Google.",
    mainbutton: "BASHKOHU ME NE!",
  },
];
export const FooterTabDataMk = [
  {
    title: "Експресна Достава",
    desc: "Вашата нарачка ќе биде доставена во рок од 3-5 работни денови откако сите ваши производи ќе бидат готови.",
    title1: "Најдобри Цени",
    desc1: "Ќе ви донесеме најповолни производи моментално.",
    title2: "Неограничена Гаранција",
    desc2:
      "Доколку не сте задоволни со вашиот производ, можете да го вратите во било кое време.",
    titlemain: "ПРИДРУЖЕТЕ СЕ НИЕ",
    titlemaindesc: "Бидете информирани за кампањи и изненадувања",
    bottomtitle:
      "Оваа страница е заштитена од Стренд и е предмет на Политиката за приватност и Условите на услуга на Google.",
    mainbutton: "ПРИДРУЖЕТЕ СЕ НИЕ!",
  },
];
