export const ProductDetailDataEn = [
  {
    title: "24/7 Support",
    desc: "Use your Strend code to earn a 15% discount!",
    desc1: "Fast Delivery",
    title1: "Product Description",
    title2: "Size Chart",
    title3: "Product Tags",
    title4: "Reviews",
    title5: "Similar Products",
    title6: "Viewed 25 times today",
    title7: "Product Description",
    title8: "Product Reviews",
    title9: "Items Left",
    title10: "Size",
    button: "Add to Cart",
    title11: "Size Chart",
    title12: "Delivery and Returns",
    title13: "Ask a Question About the Product",
  },
];

export const ProductDetailDataAl = [
  {
    title: "Mbështetje 24/7",
    desc: "Përdorni kodin tuaj Strend për të fituar një zbritje prej 15%!",
    desc1: "Dorëzim i Shpejtë",
    title1: "Përshkrimi i Produktit",
    title2: "Tabela e Madhësive",
    title3: "Etiketat e Produktit",
    title4: "Komentet",
    title5: "Produkte të Ngjashme",
    title6: "Sot është parë 25 herë",
    title7: "Përshkrimi i Produktit",
    title8: "Komentet e Produktit",
    title9: "Produkte në Dispozicion",
    title10: "Madhësia",
    button: "Shto në Shportë",
    title11: "Tabela e Madhësive",
    title12: "Dërgesa dhe Kthimi",
    title13: "Pyetje rreth Produktit",
  },
];

export const ProductDetailDataMk = [
  {
    title: "Поддршка 24/7",
    desc: "Користете го вашиот Strend код за да осигурате 15% попуст!",
    desc1: "Брза Испорака",
    title1: "Опис на Продуктот",
    title2: "Табела на Големини",
    title3: "Етикети на Продуктот",
    title4: "Коментари",
    title5: "Слични Продукти",
    title6: "Денес е видено 25 пати",
    title7: "Опис на Продуктот",
    title8: "Коментари за Продуктот",
    title9: "Продукти во Залиха",
    title10: "Големина",
    button: "Додај во Кошничка",
    title11: "Табела на Големини",
    title12: "Достава и Враќање",
    title13: "Прашања за Продуктот",
  },
];
