import { createContext, useState } from "react";

export const AuthContext = createContext(null);

const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  const setLogin = (isLoggedIn, user, token) => {
    setUser(user);
    setIsLoggedIn(isLoggedIn);
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("id", user?._id);
  };

  const setLogout = () => {
    setUser(null);
    setIsLoggedIn(false);
    localStorage.removeItem("_token");
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        user,
        setLogin,
        setLogout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
