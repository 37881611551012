export const PayDataEn = [
  {
    tabbar: "My Information",
    title: "Delivery Address",
    desc: "For quick payment",
    desc1: "Log In",
    desc2: "Sign Up",
    inputtitle: "Name",
    inputtitle1: "Surname",
    inputtitle2: "Country",
    inputtitle3: "City",
    inputtitle4: "Postal Code",
    inputtitle5: "Address",
    inputcheck: "Save address to my account",
    title1: "Billing Address",
    input2check: "Same as delivery address",
    input1title: "Country",
    input1title1: "City",
    input1title2: "Postal Code",
    input1title3: "Address",
    title2: "Delivery Method",
    check: "Standard Order €2.99 (3-5 days)",
    check1: "Express Order €10.99 (1-2 days)",
    check2: "Same Day €19.99 (Same Day)",
    title3: "Payment Method",
    check4: "Credit Card",
    check5: "Paypal",
    input2title: "Card Number",
    input2title1: "Month",
    input2title2: "Year",
    input2title3: "CVV Code",
    title4: "Order Description",
  },
];
export const PayDataMk = [
  {
    tabbar: "Мои информации",
    title: "Адреса за испорака",
    desc: "За брзо плаќање",
    desc1: "Најави се",
    desc2: "Регистрирај се",
    inputtitle: "Име",
    inputtitle1: "Презиме",
    inputtitle2: "Земја",
    inputtitle3: "Град",
    inputtitle4: "Поштенски код",
    inputtitle5: "Адреса",
    inputcheck: "Зачувај ја адресата во мојот профил",
    title1: "Адреса за фактурирање",
    input2check: "Иста како адресата за испорака",
    input1title: "Земја",
    input1title1: "Град",
    input1title2: "Поштенски код",
    input1title3: "Адреса",
    title2: "Метод за испорака",
    check: "Стандардна нарачка €2.99 (3-5 дена)",
    check1: "Брза нарачка €10.99 (1-2 дена)",
    check2: "Ист ден €19.99 (Ист ден)",
    title3: "Метод на плаќање",
    check4: "Кредитна картичка",
    check5: "Paypal",
    input2title: "Број на картичка",
    input2title1: "Месец",
    input2title2: "Година",
    input2title3: "CVV код",
    title4: "Опис на нарачката",
  },
];
export const PayDataAL = [
  {
    tabbar: "Të dhënat e mija",
    title: "Adresa e Dorëzimit",
    desc: "Për pagesë të shpejtë",
    desc1: "Hyrje",
    desc2: "Regjistrim",
    inputtitle: "Emër",
    inputtitle1: "Mbiemër",
    inputtitle2: "Shtet",
    inputtitle3: "Qytet",
    inputtitle4: "Kodi Postar",
    inputtitle5: "Adresa",
    inputcheck: "Ruaj adresën në llogarinë time",
    title1: "Adresa e Faturës",
    input2check: "E njëjta si adresa e dorëzimit",
    input1title: "Shtet",
    input1title1: "Qytet",
    input1title2: "Kodi Postar",
    input1title3: "Adresa",
    title2: "Metoda e Dorëzimit",
    check: "Porosia Standarde €2.99 (3-5 ditë)",
    check1: "Porosia Express €10.99 (1-2 ditë)",
    check2: "E njëjta Ditë €19.99 (E njëjta Ditë)",
    title3: "Metoda e Pagesës",
    check4: "Kartë Krediti",
    check5: "Paypal",
    input2title: "Numri i Kartës",
    input2title1: "Muaji",
    input2title2: "Viti",
    input2title3: "Kodi CVV",
    title4: "Përshkrimi i Porosisë",
  },
];
