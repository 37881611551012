import React, { createContext, useState, useEffect } from "react";
import AuthContextProvider from "./AuthContext";
import axios from "axios";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const companyId = "6571c83820ef3561611348af";
  const id = sessionStorage.getItem("id");
  const token = sessionStorage.getItem("token");
  const [userDesc, setUserDesc] = useState();
  const [openOverview, setOpenOverview] = useState(false);
  const [basketProducts, setBasketProducts] = useState([]);
  const [basketBadge, setBasketBadge] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [headerNo, setHeaderNo] = useState(1);
  const [footerNo, setFooterNo] = useState(4);
  const [name, setName] = useState();
  const [mail, setMail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [confirmPassword2, setConfirmPassword2] = useState();
  const [usersAddresses, setUsersAddresses] = useState([]);
  const [usersAddressesid, setUsersAddressesid] = useState();
  const [nameAddress, setNameAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [addressHeader, setAddressHeader] = useState("");
  const [addressDescription, setAddressDescription] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [currentCounty, setCurrentCounty] = useState("");
  const [currentDistrict, setCurrentDistrict] = useState("");
  const [zip, setZip] = useState("");
  const [data, setData] = useState([]);
  const [datanew, setDatanew] = useState([]);
  const [dataLength, setDataLength] = useState();
  const [productInfos, setProductInfos] = useState([]);
  const [productInfoId, setProductInfoId] = useState();
  const [myFavorites, setMyFavorites] = useState([]);
  const [myFavoriteslength, setMyFavoriteslength] = useState(0);
  const [pagination, setPagination] = useState(1);
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    sessionStorage.setItem("basketProducts", JSON.stringify(basketProducts));
  });

  const getUser = async () => {
    try {
      const response = await axios.get(`${baseUrl}/${companyId}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUserDesc(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(
        `${baseUrl}/${companyId}/users/${id}`,
        {
          name: name,
          email: mail,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        getUser();
        setName();
        setMail();
        console.log("response", response);
        // toast.success('Profiliniz Güncellendi')
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();
    if (confirmPassword === confirmPassword2) {
      try {
        const response = await axios.post(
          `${baseUrl}/${companyId}/users/${id}/change-password`,
          {
            currentPassword: password,
            newPassword: confirmPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          console.log("güncellendi pass", response);
          // toast.success('Şifre Başarıyla Güncellendi')
        }
        console.log(response);
      } catch (error) {
        console.log(error.message);
      }
    } else {
      // toast.warning("Şifreler eşleşmiyor!")
    }
  };
  const getAddresses = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/${companyId}/addresses?filter={"userId": "${id}"}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) setUsersAddresses(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const deleteAddress = async () => {
    try {
      const response = await axios.delete(
        `${baseUrl}/${companyId}/addresses/${usersAddressesid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        getAddresses();
        console.log("response delete address", response);
        // toast.success("Adresiniz Silindi");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const addAddress = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/${companyId}/addresses`,
        {
          title: addressHeader,
          fullName: nameAddress,
          address: address,
          neighborhood: currentDistrict,
          addressDescription: addressDescription,
          postalCode: zip,
          district: currentCounty,
          city: currentCity,
          country: "Türkiye",
          userId: id,
          phoneNumber: phone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        getAddresses();
      }
      console.log("ekle :", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateAddress = async () => {
    try {
      const response = await axios.put(
        `${baseUrl}/${companyId}/addresses/${usersAddressesid}`,
        {
          title: addressHeader,
          fullName: nameAddress,
          address: address,
          neighborhood: currentDistrict,
          addressDescription: addressDescription,
          postalCode: zip,
          district: currentCounty,
          city: currentCity,
          country: "Türkiye",
          userId: id,
          phoneNumber: phone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("update: ", response);
      if (response.status === 200) {
        getAddresses();
        // toast.success("Adresiniz Güncellendi");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const categoriesData = async () => {
    try {
      const pathname = window.location.pathname;
      let dataIndex = "Kadin";
      if (pathname.includes("/man")) {
        dataIndex = "Erkek";
      } else {
        dataIndex = "Kadin";
      }
      const response = await axios.get(
        `https://reddreams.xyz/Getproduct/${dataIndex}/${pagination}`
      );
      const newData = response?.data?.filter(
        (item) => item.sizeValues && item.sizeValues.length > 0
      );

      console.log("data new", newData);

      if (newData.length > 0) {
        setDatanew(newData);
      }

      // const varyant = response?.data?.map((item) => item?.sizeValues);

      // const nonEmptyVaryant = varyant.filter((item) => item && item.length > 0);

      // console.log("data new", nonEmptyVaryant);

      // if (nonEmptyVaryant.length > 0) {
      //   const newData = response?.data?.map((item) => {
      //     return {
      //       ...item,
      //       sizeValues:
      //         item?.sizeValues && item?.sizeValues.length > 0
      //           ? item.sizeValues
      //           : undefined,
      //     };
      //   });

      //   setDatanew(newData);
      // }
    } catch (error) {
      console.log("hataa", error.message);
    }
  };

  const handleProductDesc = async () => {
    const pathname = window.location.pathname;
    const pathParts = pathname.split("/");
    const id = pathParts[pathParts.length - 1];

    try {
      const response = await axios.get(
        `https://reddreams.xyz/Getproductsingle/${id}`
      );
      console.log("response", response.data);
      setProductInfos(response?.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    categoriesData();
  }, []);

  const getCategories = async () => {
    try {
      const pathname = window.location.pathname;
      let dataIndex = 1;

      if (pathname.includes("/Kadin") || pathname.includes("/kad%C4%B1n")) {
        dataIndex = 0;
      }
      const response = await axios.get(`${baseUrl}/${companyId}/products`);

      setData(response.data.data[dataIndex]);
      setDataLength(response.data.data[dataIndex]?.length);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getFavorites = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/${companyId}/users/${id}/favorites`
      );

      if (response.status) {
        setMyFavorites(response?.data?.data);
        setMyFavoriteslength(response.data.data.length);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    categoriesData();
    getUser();
    getAddresses();
    getCategories();
    getFavorites();
    handleProductDesc();
  }, [usersAddressesid]);

  return (
    <AppContext.Provider
      value={{
        setCategoryName,
        categoryName,
        setPagination,
        datanew,
        dataLength,
        myFavoriteslength,
        baseUrl,
        companyId,
        data,
        myFavorites,
        getFavorites,
        setProductInfoId,
        setProductInfos,
        productInfos,
        updateAddress,
        addAddress,
        addressHeader,
        nameAddress,
        phone,
        address,
        currentCity,
        currentCounty,
        currentDistrict,
        addressDescription,
        zip,
        setZip,
        setNameAddress,
        setPhone,
        setAddress,
        setCurrentCity,
        setCurrentCounty,
        setCurrentDistrict,
        setAddressDescription,
        setAddressHeader,
        deleteAddress,
        setUsersAddressesid,
        usersAddresses,
        changePassword,
        setPassword,
        password,
        setConfirmPassword,
        confirmPassword,
        setConfirmPassword2,
        confirmPassword2,
        setName,
        updateUser,
        setMail,
        name,
        mail,
        userDesc,
        openOverview,
        setOpenOverview,
        basketProducts,
        setBasketProducts,
        totalPrice,
        setTotalPrice,
        basketBadge,
        setBasketBadge,
        headerNo,
        setHeaderNo,
        footerNo,
        setFooterNo,
      }}
    >
      <AuthContextProvider>{children}</AuthContextProvider>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
