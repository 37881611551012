import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { FooterDataAl, FooterDataMk, FooterDataEn } from "./data/Footer";
import {
  FooterTabDataAl,
  FooterTabDataMk,
  FooterTabDataEn,
} from "./data/FooterTab";
import {
  LayoutDataEn,
  LayoutDataAl,
  LayoutDataMk,
  SubCategoryDataEn,
  SubCategoryDataMk,
  SubCategoryDataAl,
} from "./data/Layout";
import {
  LoginModalEn,
  LoginModalAl,
  LoginModalMk,
  LoginEn,
  LoginMk,
  LoginAl,
  SigninEn,
  SigninMk,
  SigninAl,
  PassAl,
  PassEn,
  PassMk,
} from "./data/Authl";
import {
  BasketModalEn,
  BasketAl,
  BasketModalAl,
  BasketModalMk,
  BasketEn,
  BasketMk,
} from "./data/Basket";
import {
  CategoryDataEn,
  CategoryDataMk,
  CategoryDataAl,
} from "./data/Category";
import { PayDataAL, PayDataEn, PayDataMk } from "./data/Pay";
import {
  ProductDetailDataAl,
  ProductDetailDataEn,
  ProductDetailDataMk,
} from "./data/Product";
import { UserDataEn, UserDataAl, UserDataMk } from "./data/User";
const resources = {
  en: {
    translation: {
      navbar: SubCategoryDataEn[0].title,
      navbartitle: SubCategoryDataEn[0].subtitle,
      navbartitle1: SubCategoryDataEn[0].subtitle1,
      navbartitle2: SubCategoryDataEn[0].subtitle2,
      navbartitle3: SubCategoryDataEn[0].subtitle3,
      navbartitle4: SubCategoryDataEn[0].subtitle4,
      navbartitle5: SubCategoryDataEn[0].subtitle5,
      navbartitle6: SubCategoryDataEn[0].subtitle6,
      navbartitlesubname: SubCategoryDataEn[0].subtitlesubname1,
      navbartitlesubname1: SubCategoryDataEn[0].subtitlesubname2,
      navbartitlesubname2: SubCategoryDataEn[0].subtitlesubname3,
      navbartitlesubname3: SubCategoryDataEn[0].subtitlesubname4,
      navbartitlesubname4: SubCategoryDataEn[0].subtitlesubname5,
      navbartitlesubname5: SubCategoryDataEn[0].subtitlesubname6,
      navbartitlesubname6: SubCategoryDataEn[0].subtitlesubname7,
      navbartitlesubname7: SubCategoryDataEn[0].subtitlesubname8,
      navbartitlesubname8: SubCategoryDataEn[0].subtitlesubname9,
      navbartitlesubname9: SubCategoryDataEn[0].subtitlesubname10,
      navbartitlesubname10: SubCategoryDataEn[0].subtitlesubname11,
      navbartitlesubname11: SubCategoryDataEn[0].subtitlesubname12,
      navbartitlesubname12: SubCategoryDataEn[0].subtitlesubname13,
      navbartitlesubname13: SubCategoryDataEn[0].subtitlesubname14,
      navbartitlesubname14: SubCategoryDataEn[0].subtitlesubname15,
      navbartitlesubname15: SubCategoryDataEn[0].subtitlesubname16,
      navbartitlesubname16: SubCategoryDataEn[0].subtitlesubname17,
      navbartitlesubname17: SubCategoryDataEn[0].subtitlesubname18,
      navbartitlesubname18: SubCategoryDataEn[0].subtitlesubname19,

      navbartitle1subname: SubCategoryDataEn[0].subtitle1subname1,
      navbartitle1subname1: SubCategoryDataEn[0].subtitle1subname2,
      navbartitle1subname2: SubCategoryDataEn[0].subtitle1subname3,
      navbartitle1subname3: SubCategoryDataEn[0].subtitle1subname4,
      navbartitle1subname4: SubCategoryDataEn[0].subtitle1subname5,
      navbartitle1subname5: SubCategoryDataEn[0].subtitle1subname6,
      navbartitle1subname6: SubCategoryDataEn[0].subtitle1subname7,
      navbartitle1subname7: SubCategoryDataEn[0].subtitle1subname8,

      navbartitle2subname: SubCategoryDataEn[0].subtitle2subname1,
      navbartitle2subname1: SubCategoryDataEn[0].subtitle2subname2,
      navbartitle2subname2: SubCategoryDataEn[0].subtitle2subname3,
      navbartitle2subname3: SubCategoryDataEn[0].subtitle2subname4,
      navbartitle2subname4: SubCategoryDataEn[0].subtitle2subname5,
      navbartitle2subname5: SubCategoryDataEn[0].subtitle2subname6,
      navbartitle2subname6: SubCategoryDataEn[0].subtitle2subname7,
      navbartitle2subname7: SubCategoryDataEn[0].subtitle2subname8,
      navbartitle2subname8: SubCategoryDataEn[0].subtitle2subname9,
      navbartitle2subname9: SubCategoryDataEn[0].subtitle2subname10,
      navbartitle2subname10: SubCategoryDataEn[0].subtitle2subname11,

      navbartitle3subname: SubCategoryDataEn[0].subtitle3subname1,
      navbartitle3subname1: SubCategoryDataEn[0].subtitle3subname2,
      navbartitle3subname2: SubCategoryDataEn[0].subtitle3subname3,
      navbartitle3subname3: SubCategoryDataEn[0].subtitle3subname4,
      navbartitle3subname4: SubCategoryDataEn[0].subtitle3subname5,
      navbartitle3subname5: SubCategoryDataEn[0].subtitle3subname6,
      navbartitle3subname6: SubCategoryDataEn[0].subtitle3subname7,
      navbartitle3subname7: SubCategoryDataEn[0].subtitle3subname8,
      navbartitle3subname8: SubCategoryDataEn[0].subtitle3subname9,
      navbartitle3subname9: SubCategoryDataEn[0].subtitle3subname10,
      navbartitle3subname10: SubCategoryDataEn[0].subtitle3subname11,
      navbartitle3subname11: SubCategoryDataEn[0].subtitle3subname12,
      navbartitle3subname12: SubCategoryDataEn[0].subtitle3subname13,
      navbartitle3subname13: SubCategoryDataEn[0].subtitle3subname14,
      navbartitle3subname14: SubCategoryDataEn[0].subtitle3subname15,
      navbartitle3subname15: SubCategoryDataEn[0].subtitle3subname16,
      navbartitle3subname16: SubCategoryDataEn[0].subtitle3subname17,
      navbartitle3subname17: SubCategoryDataEn[0].subtitle3subname18,
      navbartitle3subname18: SubCategoryDataEn[0].subtitle3subname19,
      navbartitle3subname19: SubCategoryDataEn[0].subtitle3subname20,

      navbartitle4subname: SubCategoryDataEn[0].subtitle4subname1,
      navbartitle4subname1: SubCategoryDataEn[0].subtitle4subname2,
      navbartitle4subname2: SubCategoryDataEn[0].subtitle4subname3,
      navbartitle4subname3: SubCategoryDataEn[0].subtitle4subname4,
      navbartitle4subname4: SubCategoryDataEn[0].subtitle4subname5,
      navbartitle4subname5: SubCategoryDataEn[0].subtitle4subname6,
      navbartitle4subname6: SubCategoryDataEn[0].subtitle4subname7,
      navbartitle4subname7: SubCategoryDataEn[0].subtitle4subname8,
      navbartitle4subname8: SubCategoryDataEn[0].subtitle4subname9,
      navbartitle4subname9: SubCategoryDataEn[0].subtitle4subname10,
      navbartitle4subname10: SubCategoryDataEn[0].subtitle4subname11,
      navbartitle4subname11: SubCategoryDataEn[0].subtitle4subname12,
      navbartitle4subname12: SubCategoryDataEn[0].subtitle4subname13,

      navbartitle5subname: SubCategoryDataEn[0].subtitle5subname1,
      navbartitle5subname1: SubCategoryDataEn[0].subtitle5subname2,
      navbartitle5subname2: SubCategoryDataEn[0].subtitle5subname3,
      navbartitle5subname3: SubCategoryDataEn[0].subtitle5subname4,
      navbartitle5subname4: SubCategoryDataEn[0].subtitle5subname5,

      navbartitle6subname1: SubCategoryDataEn[0].subtitle6subname2,
      navbartitle6subname2: SubCategoryDataEn[0].subtitle6subname3,
      navbartitle6subname3: SubCategoryDataEn[0].subtitle6subname4,
      navbartitle6subname4: SubCategoryDataEn[0].subtitle6subname5,
      navbartitle6subname5: SubCategoryDataEn[0].subtitle6subname6,
      navbartitle6subname6: SubCategoryDataEn[0].subtitle6subname7,
      navbartitle6subname7: SubCategoryDataEn[0].subtitle6subname8,
      navbartitle6subname8: SubCategoryDataEn[0].subtitle6subname9,
      navbartitle6subname9: SubCategoryDataEn[0].subtitle6subname10,
      navbartitle6subname10: SubCategoryDataEn[0].subtitle6subname11,
      navbartitle6subname11: SubCategoryDataEn[0].subtitle6subname12,
      navbartitle6subname12: SubCategoryDataEn[0].subtitle6subname13,
      navbartitle6subname13: SubCategoryDataEn[0].subtitle6subname14,
      navbartitle6subname14: SubCategoryDataEn[0].subtitle6subname15,
      navbartitle6subname15: SubCategoryDataEn[0].subtitle6subname16,
      navbartitle6subname16: SubCategoryDataEn[0].subtitle6subname17,
      navbartitle6subname17: SubCategoryDataEn[0].subtitle6subname18,
      navbartitle6subname18: SubCategoryDataEn[0].subtitle6subname19,
      navbartitle6subname19: SubCategoryDataEn[0].subtitle6subname20,

      navbar1: SubCategoryDataEn[1].title,
      navbar1title: SubCategoryDataEn[1].subtitle,
      navbar1title1: SubCategoryDataEn[1].subtitle1,
      navbar1title2: SubCategoryDataEn[1].subtitle2,
      navbar1title3: SubCategoryDataEn[1].subtitle3,
      navbar1title4: SubCategoryDataEn[1].subtitle4,
      navbar1title5: SubCategoryDataEn[1].subtitle5,
      navbar1title6: SubCategoryDataEn[1].subtitle6,
      navbar1title7: SubCategoryDataEn[1].subtitle7,

      navbar1titlesubname: SubCategoryDataEn[1].subtitlesubname1,
      navbar1titlesubname1: SubCategoryDataEn[1].subtitlesubname2,
      navbar1titlesubname2: SubCategoryDataEn[1].subtitlesubname3,
      navbar1titlesubname3: SubCategoryDataEn[1].subtitlesubname4,
      navbar1titlesubname4: SubCategoryDataEn[1].subtitlesubname5,
      navbar1titlesubname5: SubCategoryDataEn[1].subtitlesubname6,
      navbar1titlesubname6: SubCategoryDataEn[1].subtitlesubname7,
      navbar1titlesubname7: SubCategoryDataEn[1].subtitlesubname8,
      navbar1titlesubname8: SubCategoryDataEn[1].subtitlesubname9,
      navbar1titlesubname9: SubCategoryDataEn[1].subtitlesubname10,
      navbar1titlesubname10: SubCategoryDataEn[1].subtitlesubname11,
      navbar1titlesubname11: SubCategoryDataEn[1].subtitlesubname12,
      navbar1titlesubname12: SubCategoryDataEn[1].subtitlesubname13,
      navbar1titlesubname13: SubCategoryDataEn[1].subtitlesubname14,
      navbar1titlesubname14: SubCategoryDataEn[1].subtitlesubname15,
      navbar1titlesubname15: SubCategoryDataEn[1].subtitlesubname16,
      navbar1titlesubname16: SubCategoryDataEn[1].subtitlesubname17,
      navbar1titlesubname17: SubCategoryDataEn[1].subtitlesubname18,
      navbar1titlesubname18: SubCategoryDataEn[1].subtitlesubname19,
      navbar1titlesubname19: SubCategoryDataEn[1].subtitlesubname20,

      navbar1title1subname: SubCategoryDataEn[1].subtitle1subname1,
      navbar1title1subname1: SubCategoryDataEn[1].subtitle1subname2,
      navbar1title1subname2: SubCategoryDataEn[1].subtitle1subname3,
      navbar1title1subname3: SubCategoryDataEn[1].subtitle1subname4,
      navbar1title1subname4: SubCategoryDataEn[1].subtitle1subname5,
      navbar1title1subname5: SubCategoryDataEn[1].subtitle1subname6,
      navbar1title1subname6: SubCategoryDataEn[1].subtitle1subname7,
      navbar1title1subname7: SubCategoryDataEn[1].subtitle1subname8,
      navbar1title1subname8: SubCategoryDataEn[1].subtitle1subname9,
      navbar1title1subname9: SubCategoryDataEn[1].subtitle1subname10,
      navbar1title1subname10: SubCategoryDataEn[1].subtitle1subname11,
      navbar1title1subname11: SubCategoryDataEn[1].subtitle1subname12,
      navbar1title1subname12: SubCategoryDataEn[1].subtitle1subname13,

      navbar1title2subname: SubCategoryDataEn[1].subtitle2subname1,
      navbar1title2subname1: SubCategoryDataEn[1].subtitle2subname2,
      navbar1title2subname2: SubCategoryDataEn[1].subtitle2subname3,
      navbar1title2subname3: SubCategoryDataEn[1].subtitle2subname4,
      navbar1title2subname4: SubCategoryDataEn[1].subtitle2subname5,
      navbar1title2subname5: SubCategoryDataEn[1].subtitle2subname6,
      navbar1title2subname6: SubCategoryDataEn[1].subtitle2subname7,
      navbar1title2subname7: SubCategoryDataEn[1].subtitle2subname8,
      navbar1title2subname8: SubCategoryDataEn[1].subtitle2subname9,

      navbar1title3subname: SubCategoryDataEn[1].subtitle3subname1,
      navbar1title3subname1: SubCategoryDataEn[1].subtitle3subname2,
      navbar1title3subname2: SubCategoryDataEn[1].subtitle3subname3,
      navbar1title3subname3: SubCategoryDataEn[1].subtitle3subname4,
      navbar1title3subname4: SubCategoryDataEn[1].subtitle3subname5,
      navbar1title3subname5: SubCategoryDataEn[1].subtitle3subname6,
      navbar1title3subname6: SubCategoryDataEn[1].subtitle3subname7,
      navbar1title3subname7: SubCategoryDataEn[1].subtitle3subname8,
      navbar1title3subname8: SubCategoryDataEn[1].subtitle3subname9,

      navbar1title4subname: SubCategoryDataEn[1].subtitle4subname1,
      navbar1title4subname1: SubCategoryDataEn[1].subtitle4subname2,
      navbar1title4subname2: SubCategoryDataEn[1].subtitle4subname3,
      navbar1title4subname3: SubCategoryDataEn[1].subtitle4subname4,
      navbar1title4subname4: SubCategoryDataEn[1].subtitle4subname5,
      navbar1title4subname5: SubCategoryDataEn[1].subtitle4subname6,
      navbar1title4subname6: SubCategoryDataEn[1].subtitle4subname7,
      navbar1title4subname7: SubCategoryDataEn[1].subtitle4subname8,
      navbar1title4subname8: SubCategoryDataEn[1].subtitle4subname9,
      navbar1title4subname9: SubCategoryDataEn[1].subtitle4subname10,
      navbar1title4subname10: SubCategoryDataEn[1].subtitle4subname11,
      navbar1title4subname11: SubCategoryDataEn[1].subtitle4subname12,
      navbar1title4subname12: SubCategoryDataEn[1].subtitle4subname13,

      navbar1title5subname: SubCategoryDataEn[1].subtitle5subname1,
      navbar1title5subname1: SubCategoryDataEn[1].subtitle5subname2,
      navbar1title5subname2: SubCategoryDataEn[1].subtitle5subname3,
      navbar1title5subname3: SubCategoryDataEn[1].subtitle5subname4,
      navbar1title5subname4: SubCategoryDataEn[1].subtitle5subname5,

      navbar1title6subname1: SubCategoryDataEn[1].subtitle6subname2,
      navbar1title6subname2: SubCategoryDataEn[1].subtitle6subname3,
      navbar1title6subname3: SubCategoryDataEn[1].subtitle6subname4,
      navbar1title6subname4: SubCategoryDataEn[1].subtitle6subname5,
      navbar1title6subname5: SubCategoryDataEn[1].subtitle6subname6,
      navbar1title6subname6: SubCategoryDataEn[1].subtitle6subname7,
      navbar1title6subname7: SubCategoryDataEn[1].subtitle6subname8,
      navbar1title6subname8: SubCategoryDataEn[1].subtitle6subname9,
      navbar1title6subname9: SubCategoryDataEn[1].subtitle6subname10,
      navbar1title6subname10: SubCategoryDataEn[1].subtitle6subname11,
      navbar1title6subname11: SubCategoryDataEn[1].subtitle6subname12,
      navbar1title6subname12: SubCategoryDataEn[1].subtitle6subname13,
      navbar1title6subname13: SubCategoryDataEn[1].subtitle6subname14,
      navbar1title6subname14: SubCategoryDataEn[1].subtitle6subname15,
      navbar1title6subname15: SubCategoryDataEn[1].subtitle6subname16,
      navbar1title6subname16: SubCategoryDataEn[1].subtitle6subname17,
      navbar1title6subname17: SubCategoryDataEn[1].subtitle6subname18,
      navbar1title6subname18: SubCategoryDataEn[1].subtitle6subname19,
      navbar1title6subname19: SubCategoryDataEn[1].subtitle6subname20,

      navbar1title7subname: SubCategoryDataEn[1].subtitle7subname1,
      navbar1title7subname1: SubCategoryDataEn[1].subtitle7subname2,
      navbar1title7subname2: SubCategoryDataEn[1].subtitle7subname3,

      usertitle: UserDataEn[0].title,
      usertitle1: UserDataEn[0].title1,
      usertitle2: UserDataEn[0].title2,
      usertitle3: UserDataEn[0].title3,
      usertitle4: UserDataEn[0].title4,
      usertitle5: UserDataEn[0].title5,
      usertitle6: UserDataEn[0].title6,
      usertitle7: UserDataEn[0].title7,
      usertitle8: UserDataEn[0].title8,
      usertitle9: UserDataEn[0].title9,
      usertitle10: UserDataEn[0].title10,
      usertitle11: UserDataEn[0].title11,
      usertitle12: UserDataEn[0].title12,
      usertitle13: UserDataEn[0].title13,
      usertitle14: UserDataEn[0].title14,
      usertitle15: UserDataEn[0].title15,
      usertitle16: UserDataEn[0].title16,
      usertitle17: UserDataEn[0].title17,
      useraddress: UserDataEn[0].address,
      useraddress1: UserDataEn[0].address1,
      useraddress2: UserDataEn[0].address2,
      useraddress3: UserDataEn[0].address3,
      useraddress4: UserDataEn[0].address4,
      useraddress5: UserDataEn[0].address5,
      useraddress6: UserDataEn[0].address6,
      useraddress7: UserDataEn[0].address7,
      useraddress8: UserDataEn[0].address8,
      useraddress9: UserDataEn[0].address9,
      useraddress10: UserDataEn[0].address10,
      useraddress11: UserDataEn[0].address11,
      useraddress12: UserDataEn[0].address12,
      useraddress13: UserDataEn[0].address13,
      useraddress14: UserDataEn[0].address14,
      useraddress15: UserDataEn[0].address15,
      userfav: UserDataEn[0].fav,
      userproduct: UserDataEn[0].product,
      userproduct1: UserDataEn[0].product1,
      userproduct2: UserDataEn[0].product2,
      userproduc3: UserDataEn[0].product3,
      userproduct4: UserDataEn[0].product4,
      productdetailtitle: ProductDetailDataEn[0].title,
      productdetaildesc: ProductDetailDataEn[0].desc,
      productdetaildesc1: ProductDetailDataEn[0].desc1,
      productdetailtitle1: ProductDetailDataEn[0].title1,
      productdetailtitle2: ProductDetailDataEn[0].title2,
      productdetailtitle3: ProductDetailDataEn[0].title3,
      productdetailtitle4: ProductDetailDataEn[0].title4,
      productdetailtitle5: ProductDetailDataEn[0].title5,
      productdetailtitle6: ProductDetailDataEn[0].title6,
      productdetailtitle7: ProductDetailDataEn[0].title7,
      productdetailtitle8: ProductDetailDataEn[0].title8,
      productdetailtitle9: ProductDetailDataEn[0].title9,
      productdetailtitle10: ProductDetailDataEn[0].title10,
      productdetailtitle11: ProductDetailDataEn[0].title11,
      productdetailtitle12: ProductDetailDataEn[0].title12,
      productdetailtitle13: ProductDetailDataEn[0].title13,
      productdetailbutton: ProductDetailDataEn[0].button,
      pay: PayDataEn[0].tabbar,
      paytitle: PayDataEn[0].title,
      paydesc: PayDataEn[0].desc,
      paydesc1: PayDataEn[0].desc1,
      paydesc2: PayDataEn[0].desc2,
      payinput: PayDataEn[0].inputtitle,
      payinput1: PayDataEn[0].inputtitle1,
      payinput2: PayDataEn[0].inputtitle2,
      payinput3: PayDataEn[0].inputtitle3,
      payinput4: PayDataEn[0].inputtitle4,
      paycheck: PayDataEn[0].inputcheck,
      paytitle1: PayDataEn[0].title1,
      payinputcheck: PayDataEn[0].input2check,
      pay1input: PayDataEn[0].input1title,
      pay1input1: PayDataEn[0].input1title1,
      pay1input2: PayDataEn[0].input1title2,
      pay1input3: PayDataEn[0].input1title3,
      paytitle2: PayDataEn[0].title2,
      paycheck1: PayDataEn[0].check,
      paycheck2: PayDataEn[0].check1,
      paycheck3: PayDataEn[0].check2,
      paytitle3: PayDataEn[0].title3,
      paycheck4: PayDataEn[0].check4,
      paycheck5: PayDataEn[0].check5,
      pay2input: PayDataEn[0].input2title,
      pay2input1: PayDataEn[0].input2title1,
      pay2input2: PayDataEn[0].input2title2,
      pay2input3: PayDataEn[0].input2title3,
      paytitle4: PayDataEn[0].title4,
      category: CategoryDataEn[0].tabbar,
      category1: CategoryDataEn[0].tabbar2,
      categorysort: CategoryDataEn[0].sortelement,
      categorysort1: CategoryDataEn[0].sortelement1,
      categorysort2: CategoryDataEn[0].sortelement2,
      categorysort3: CategoryDataEn[0].sortelement3,
      categorysort4: CategoryDataEn[0].sortelement4,
      categorysorttrend: CategoryDataEn[0].sortelementend,
      categoryfilter: CategoryDataEn[0].filtertitle,
      categoryfilter1: CategoryDataEn[0].filtertitle1,
      categoryfilter2: CategoryDataEn[0].filtertitle2,
      categoryfilter3: CategoryDataEn[0].filtertitle3,
      categoryfilter4: CategoryDataEn[0].filtertitle4,
      categoryfilterbutton: CategoryDataEn[0].filterbutton,
      basket: BasketEn[0].title,
      basket1: BasketEn[0].title1,
      basket2: BasketEn[0].title2,
      basketpay: BasketEn[0].allpay,
      basketbutton: BasketEn[0].button,
      basketdetail: BasketEn[0].changebuttontitle,
      basketdetail1: BasketEn[0].changebuttontitle1,
      basketbuttonall: BasketEn[0].buttonall,
      basketform: BasketEn[0].form,
      basketformdetail: BasketEn[0].formcountry,
      basketformdetailcity: BasketEn[0].formcity,
      basketformdetailzip: BasketEn[0].formpostcode,
      basketform2: BasketEn[0].form2,
      basketformdesc: BasketEn[0].form2desc,
      basketforminput: BasketEn[0].forminput,
      basketformbutton: BasketEn[0].formbutton,
      basketform3: BasketEn[0].form3,
      basketmodal: BasketModalEn[0].title,
      basketmodal1: BasketModalEn[0].title2,
      basketmodaltotal: BasketModalEn[0].footerpay,
      basketmodalbutton: BasketModalEn[0].gobasket,
      basketmodalbutton1: BasketModalEn[0].buy,
      loginmodal: LoginModalEn[0].title,
      loginmodal2: LoginModalEn[0].title1,
      loginmodal3: LoginModalEn[0].title2,
      loginmodalbutton: LoginModalEn[0].button,
      loginmodaldetail: LoginModalEn[0].detail,
      loginmodaldetailmain: LoginModalEn[0].detailmain,
      passtab: PassEn[0].tabbar,
      passtab1: PassEn[0].tabbar2,
      passdesc: PassEn[0].desc,
      passbutton: PassEn[0].button,
      padddetail: PassEn[0].detail,
      padddetaildesc: PassEn[0].detaildesc,
      padddetailtitle: PassEn[0].detailtitle,
      padddetaillist: PassEn[0].detaillist,
      padddetaillist1: PassEn[0].detaillist1,
      padddetaillist2: PassEn[0].detaillist2,
      padddetailbutton: PassEn[0].detailbutton,
      padddetailbutton1: PassEn[0].detailbutton1,
      authsign: SigninEn[0].tabbar,
      authsign2: SigninEn[0].tabbar2,
      authsigndesc: SigninEn[0].desc,
      authsigntitle: SigninEn[0].title,
      authsigntitle1: SigninEn[0].title1,
      authsigntitle2: SigninEn[0].title2,
      authsigntitle3: SigninEn[0].title3,
      authsigntitle4: SigninEn[0].title4,
      authsigncheck: SigninEn[0].check,
      authsignbutton: SigninEn[0].button,
      authtab: LoginEn[0].tabbar,
      authtab2: LoginEn[0].tabbar2,
      authlogin: LoginEn[0].title,
      authlogindesc: LoginEn[0].desc,
      authtitle: LoginEn[0].title,
      authtitle2: LoginEn[0].title2,
      autcheck: LoginEn[0].check,
      autpass: LoginEn[0].pass,
      authbutton: LoginEn[0].button,
      autsubdesc: LoginEn[0].subdesc,
      header: LayoutDataEn[0].header,
      header1: LayoutDataEn[0].header1,
      header2: LayoutDataEn[0].header2,
      homename: LayoutDataEn[0].title,
      homename2: LayoutDataEn[0].title2,
      homedesc: LayoutDataEn[0].title3,
      homecategory: LayoutDataEn[0].categorytitle,
      homecategory2: LayoutDataEn[0].categorytitle2,
      homecategorydesc: LayoutDataEn[0].categorydesc,
      homecategorydesc2: LayoutDataEn[0].categorydesc2,
      homeslider: LayoutDataEn[0].slider,
      homesliderdesc: LayoutDataEn[0].sliderdesc,
      homesliderbutton: LayoutDataEn[0].sliderbutton,
      homebanner: LayoutDataEn[0].banner,
      homebannerdesc: LayoutDataEn[0].bannerdesc,
      homebannerbutton: LayoutDataEn[0].bannerbutton,
      tabtitle: FooterTabDataEn[0].title,
      tabtitle2: FooterTabDataEn[0].title1,
      tabtitle3: FooterTabDataEn[0].title2,
      tabdesc: FooterTabDataEn[0].desc,
      tabdesc1: FooterTabDataEn[0].desc1,
      tabdesc2: FooterTabDataEn[0].desc2,
      tabnamemain: FooterTabDataEn[0].titlemain,
      tabdescmain: FooterTabDataEn[0].titlemaindesc,
      tabbottomtitle: FooterTabDataEn[0].bottomtitle,
      tabbutton: FooterTabDataEn[0].mainbutton,
      name: FooterDataEn[0].name,
      mail: FooterDataEn[0].mail,
      time: FooterDataEn[0].time,
      footer: FooterDataEn[0].footer,
      subname: FooterDataEn[1].name,
      subtitle: FooterDataEn[1].title[0].subname,
      subtitle1: FooterDataEn[1].title[1].subname,
      subtitle2: FooterDataEn[1].title[2].subname,
      subtitle3: FooterDataEn[1].title[3].subname,
      subtitle4: FooterDataEn[1].title[4].subname,
      subname2: FooterDataEn[2].name,
      sub2title: FooterDataEn[2].title[0].subname,
      sub2title1: FooterDataEn[2].title[1].subname,
      sub2title2: FooterDataEn[2].title[2].subname,
      sub2title3: FooterDataEn[2].title[3].subname,
      sub2title4: FooterDataEn[2].title[4].subname,
      subname3: FooterDataEn[3].name,
      sub3title: FooterDataEn[3].title[0].subname,
      sub3title1: FooterDataEn[3].title[1].subname,
      sub3title2: FooterDataEn[3].title[2].subname,
    },
  },
  al: {
    translation: {
      navbar: SubCategoryDataAl[0].title,
      navbartitle: SubCategoryDataAl[0].subtitle,
      navbartitle1: SubCategoryDataAl[0].subtitle1,
      navbartitle2: SubCategoryDataAl[0].subtitle2,
      navbartitle3: SubCategoryDataAl[0].subtitle3,
      navbartitle4: SubCategoryDataAl[0].subtitle4,
      navbartitle5: SubCategoryDataAl[0].subtitle5,
      navbartitle6: SubCategoryDataAl[0].subtitle6,
      navbartitlesubname: SubCategoryDataAl[0].subtitlesubname1,
      navbartitlesubname1: SubCategoryDataAl[0].subtitlesubname2,
      navbartitlesubname2: SubCategoryDataAl[0].subtitlesubname3,
      navbartitlesubname3: SubCategoryDataAl[0].subtitlesubname4,
      navbartitlesubname4: SubCategoryDataAl[0].subtitlesubname5,
      navbartitlesubname5: SubCategoryDataAl[0].subtitlesubname6,
      navbartitlesubname6: SubCategoryDataAl[0].subtitlesubname7,
      navbartitlesubname7: SubCategoryDataAl[0].subtitlesubname8,
      navbartitlesubname8: SubCategoryDataAl[0].subtitlesubname9,
      navbartitlesubname9: SubCategoryDataAl[0].subtitlesubname10,
      navbartitlesubname10: SubCategoryDataAl[0].subtitlesubname11,
      navbartitlesubname11: SubCategoryDataAl[0].subtitlesubname12,
      navbartitlesubname12: SubCategoryDataAl[0].subtitlesubname13,
      navbartitlesubname13: SubCategoryDataAl[0].subtitlesubname14,
      navbartitlesubname14: SubCategoryDataAl[0].subtitlesubname15,
      navbartitlesubname15: SubCategoryDataAl[0].subtitlesubname16,
      navbartitlesubname16: SubCategoryDataAl[0].subtitlesubname17,
      navbartitlesubname17: SubCategoryDataAl[0].subtitlesubname18,
      navbartitlesubname18: SubCategoryDataAl[0].subtitlesubname19,

      navbartitle1subname: SubCategoryDataAl[0].subtitle1subname1,
      navbartitle1subname1: SubCategoryDataAl[0].subtitle1subname2,
      navbartitle1subname2: SubCategoryDataAl[0].subtitle1subname3,
      navbartitle1subname3: SubCategoryDataAl[0].subtitle1subname4,
      navbartitle1subname4: SubCategoryDataAl[0].subtitle1subname5,
      navbartitle1subname5: SubCategoryDataAl[0].subtitle1subname6,
      navbartitle1subname6: SubCategoryDataAl[0].subtitle1subname7,
      navbartitle1subname7: SubCategoryDataAl[0].subtitle1subname8,

      navbartitle2subname: SubCategoryDataAl[0].subtitle2subname1,
      navbartitle2subname1: SubCategoryDataAl[0].subtitle2subname2,
      navbartitle2subname2: SubCategoryDataAl[0].subtitle2subname3,
      navbartitle2subname3: SubCategoryDataAl[0].subtitle2subname4,
      navbartitle2subname4: SubCategoryDataAl[0].subtitle2subname5,
      navbartitle2subname5: SubCategoryDataAl[0].subtitle2subname6,
      navbartitle2subname6: SubCategoryDataAl[0].subtitle2subname7,
      navbartitle2subname7: SubCategoryDataAl[0].subtitle2subname8,
      navbartitle2subname8: SubCategoryDataAl[0].subtitle2subname9,
      navbartitle2subname9: SubCategoryDataAl[0].subtitle2subname10,
      navbartitle2subname10: SubCategoryDataAl[0].subtitle2subname11,

      navbartitle3subname: SubCategoryDataAl[0].subtitle3subname1,
      navbartitle3subname1: SubCategoryDataAl[0].subtitle3subname2,
      navbartitle3subname2: SubCategoryDataAl[0].subtitle3subname3,
      navbartitle3subname3: SubCategoryDataAl[0].subtitle3subname4,
      navbartitle3subname4: SubCategoryDataAl[0].subtitle3subname5,
      navbartitle3subname5: SubCategoryDataAl[0].subtitle3subname6,
      navbartitle3subname6: SubCategoryDataAl[0].subtitle3subname7,
      navbartitle3subname7: SubCategoryDataAl[0].subtitle3subname8,
      navbartitle3subname8: SubCategoryDataAl[0].subtitle3subname9,
      navbartitle3subname9: SubCategoryDataAl[0].subtitle3subname10,
      navbartitle3subname10: SubCategoryDataAl[0].subtitle3subname11,
      navbartitle3subname11: SubCategoryDataAl[0].subtitle3subname12,
      navbartitle3subname12: SubCategoryDataAl[0].subtitle3subname13,
      navbartitle3subname13: SubCategoryDataAl[0].subtitle3subname14,
      navbartitle3subname14: SubCategoryDataAl[0].subtitle3subname15,
      navbartitle3subname15: SubCategoryDataAl[0].subtitle3subname16,
      navbartitle3subname16: SubCategoryDataAl[0].subtitle3subname17,
      navbartitle3subname17: SubCategoryDataAl[0].subtitle3subname18,
      navbartitle3subname18: SubCategoryDataAl[0].subtitle3subname19,
      navbartitle3subname19: SubCategoryDataAl[0].subtitle3subname20,

      navbartitle4subname: SubCategoryDataAl[0].subtitle4subname1,
      navbartitle4subname1: SubCategoryDataAl[0].subtitle4subname2,
      navbartitle4subname2: SubCategoryDataAl[0].subtitle4subname3,
      navbartitle4subname3: SubCategoryDataAl[0].subtitle4subname4,
      navbartitle4subname4: SubCategoryDataAl[0].subtitle4subname5,
      navbartitle4subname5: SubCategoryDataAl[0].subtitle4subname6,
      navbartitle4subname6: SubCategoryDataAl[0].subtitle4subname7,
      navbartitle4subname7: SubCategoryDataAl[0].subtitle4subname8,
      navbartitle4subname8: SubCategoryDataAl[0].subtitle4subname9,
      navbartitle4subname9: SubCategoryDataAl[0].subtitle4subname10,
      navbartitle4subname10: SubCategoryDataAl[0].subtitle4subname11,
      navbartitle4subname11: SubCategoryDataAl[0].subtitle4subname12,
      navbartitle4subname12: SubCategoryDataAl[0].subtitle4subname13,

      navbartitle5subname: SubCategoryDataAl[0].subtitle5subname1,
      navbartitle5subname1: SubCategoryDataAl[0].subtitle5subname2,
      navbartitle5subname2: SubCategoryDataAl[0].subtitle5subname3,
      navbartitle5subname3: SubCategoryDataAl[0].subtitle5subname4,
      navbartitle5subname4: SubCategoryDataAl[0].subtitle5subname5,

      navbartitle6subname1: SubCategoryDataAl[0].subtitle6subname2,
      navbartitle6subname2: SubCategoryDataAl[0].subtitle6subname3,
      navbartitle6subname3: SubCategoryDataAl[0].subtitle6subname4,
      navbartitle6subname4: SubCategoryDataAl[0].subtitle6subname5,
      navbartitle6subname5: SubCategoryDataAl[0].subtitle6subname6,
      navbartitle6subname6: SubCategoryDataAl[0].subtitle6subname7,
      navbartitle6subname7: SubCategoryDataAl[0].subtitle6subname8,
      navbartitle6subname8: SubCategoryDataAl[0].subtitle6subname9,
      navbartitle6subname9: SubCategoryDataAl[0].subtitle6subname10,
      navbartitle6subname10: SubCategoryDataAl[0].subtitle6subname11,
      navbartitle6subname11: SubCategoryDataAl[0].subtitle6subname12,
      navbartitle6subname12: SubCategoryDataAl[0].subtitle6subname13,
      navbartitle6subname13: SubCategoryDataAl[0].subtitle6subname14,
      navbartitle6subname14: SubCategoryDataAl[0].subtitle6subname15,
      navbartitle6subname15: SubCategoryDataAl[0].subtitle6subname16,
      navbartitle6subname16: SubCategoryDataAl[0].subtitle6subname17,
      navbartitle6subname17: SubCategoryDataAl[0].subtitle6subname18,
      navbartitle6subname18: SubCategoryDataAl[0].subtitle6subname19,
      navbartitle6subname19: SubCategoryDataAl[0].subtitle6subname20,

      navbar1: SubCategoryDataAl[1].title,
      navbar1title: SubCategoryDataAl[1].subtitle,
      navbar1title1: SubCategoryDataAl[1].subtitle1,
      navbar1title2: SubCategoryDataAl[1].subtitle2,
      navbar1title3: SubCategoryDataAl[1].subtitle3,
      navbar1title4: SubCategoryDataAl[1].subtitle4,
      navbar1title5: SubCategoryDataAl[1].subtitle5,
      navbar1title6: SubCategoryDataAl[1].subtitle6,
      navbar1title7: SubCategoryDataAl[1].subtitle7,

      navbar1titlesubname: SubCategoryDataAl[1].subtitlesubname1,
      navbar1titlesubname1: SubCategoryDataAl[1].subtitlesubname2,
      navbar1titlesubname2: SubCategoryDataAl[1].subtitlesubname3,
      navbar1titlesubname3: SubCategoryDataAl[1].subtitlesubname4,
      navbar1titlesubname4: SubCategoryDataAl[1].subtitlesubname5,
      navbar1titlesubname5: SubCategoryDataAl[1].subtitlesubname6,
      navbar1titlesubname6: SubCategoryDataAl[1].subtitlesubname7,
      navbar1titlesubname7: SubCategoryDataAl[1].subtitlesubname8,
      navbar1titlesubname8: SubCategoryDataAl[1].subtitlesubname9,
      navbar1titlesubname9: SubCategoryDataAl[1].subtitlesubname10,
      navbar1titlesubname10: SubCategoryDataAl[1].subtitlesubname11,
      navbar1titlesubname11: SubCategoryDataAl[1].subtitlesubname12,
      navbar1titlesubname12: SubCategoryDataAl[1].subtitlesubname13,
      navbar1titlesubname13: SubCategoryDataAl[1].subtitlesubname14,
      navbar1titlesubname14: SubCategoryDataAl[1].subtitlesubname15,
      navbar1titlesubname15: SubCategoryDataAl[1].subtitlesubname16,
      navbar1titlesubname16: SubCategoryDataAl[1].subtitlesubname17,
      navbar1titlesubname17: SubCategoryDataAl[1].subtitlesubname18,
      navbar1titlesubname18: SubCategoryDataAl[1].subtitlesubname19,
      navbar1titlesubname19: SubCategoryDataAl[1].subtitlesubname20,

      navbar1title1subname: SubCategoryDataAl[1].subtitle1subname1,
      navbar1title1subname1: SubCategoryDataAl[1].subtitle1subname2,
      navbar1title1subname2: SubCategoryDataAl[1].subtitle1subname3,
      navbar1title1subname3: SubCategoryDataAl[1].subtitle1subname4,
      navbar1title1subname4: SubCategoryDataAl[1].subtitle1subname5,
      navbar1title1subname5: SubCategoryDataAl[1].subtitle1subname6,
      navbar1title1subname6: SubCategoryDataAl[1].subtitle1subname7,
      navbar1title1subname7: SubCategoryDataAl[1].subtitle1subname8,
      navbar1title1subname8: SubCategoryDataAl[1].subtitle1subname9,
      navbar1title1subname9: SubCategoryDataAl[1].subtitle1subname10,
      navbar1title1subname10: SubCategoryDataAl[1].subtitle1subname11,
      navbar1title1subname11: SubCategoryDataAl[1].subtitle1subname12,
      navbar1title1subname12: SubCategoryDataAl[1].subtitle1subname13,

      navbar1title2subname: SubCategoryDataAl[1].subtitle2subname1,
      navbar1title2subname1: SubCategoryDataAl[1].subtitle2subname2,
      navbar1title2subname2: SubCategoryDataAl[1].subtitle2subname3,
      navbar1title2subname3: SubCategoryDataAl[1].subtitle2subname4,
      navbar1title2subname4: SubCategoryDataAl[1].subtitle2subname5,
      navbar1title2subname5: SubCategoryDataAl[1].subtitle2subname6,
      navbar1title2subname6: SubCategoryDataAl[1].subtitle2subname7,
      navbar1title2subname7: SubCategoryDataAl[1].subtitle2subname8,
      navbar1title2subname8: SubCategoryDataAl[1].subtitle2subname9,

      navbar1title3subname: SubCategoryDataAl[1].subtitle3subname1,
      navbar1title3subname1: SubCategoryDataAl[1].subtitle3subname2,
      navbar1title3subname2: SubCategoryDataAl[1].subtitle3subname3,
      navbar1title3subname3: SubCategoryDataAl[1].subtitle3subname4,
      navbar1title3subname4: SubCategoryDataAl[1].subtitle3subname5,
      navbar1title3subname5: SubCategoryDataAl[1].subtitle3subname6,
      navbar1title3subname6: SubCategoryDataAl[1].subtitle3subname7,
      navbar1title3subname7: SubCategoryDataAl[1].subtitle3subname8,
      navbar1title3subname8: SubCategoryDataAl[1].subtitle3subname9,

      navbar1title4subname: SubCategoryDataAl[1].subtitle4subname1,
      navbar1title4subname1: SubCategoryDataAl[1].subtitle4subname2,
      navbar1title4subname2: SubCategoryDataAl[1].subtitle4subname3,
      navbar1title4subname3: SubCategoryDataAl[1].subtitle4subname4,
      navbar1title4subname4: SubCategoryDataAl[1].subtitle4subname5,
      navbar1title4subname5: SubCategoryDataAl[1].subtitle4subname6,
      navbar1title4subname6: SubCategoryDataAl[1].subtitle4subname7,
      navbar1title4subname7: SubCategoryDataAl[1].subtitle4subname8,
      navbar1title4subname8: SubCategoryDataAl[1].subtitle4subname9,
      navbar1title4subname9: SubCategoryDataAl[1].subtitle4subname10,
      navbar1title4subname10: SubCategoryDataAl[1].subtitle4subname11,
      navbar1title4subname11: SubCategoryDataAl[1].subtitle4subname12,
      navbar1title4subname12: SubCategoryDataAl[1].subtitle4subname13,

      navbar1title5subname: SubCategoryDataAl[1].subtitle5subname1,
      navbar1title5subname1: SubCategoryDataAl[1].subtitle5subname2,
      navbar1title5subname2: SubCategoryDataAl[1].subtitle5subname3,
      navbar1title5subname3: SubCategoryDataAl[1].subtitle5subname4,
      navbar1title5subname4: SubCategoryDataAl[1].subtitle5subname5,

      navbar1title6subname1: SubCategoryDataAl[1].subtitle6subname2,
      navbar1title6subname2: SubCategoryDataAl[1].subtitle6subname3,
      navbar1title6subname3: SubCategoryDataAl[1].subtitle6subname4,
      navbar1title6subname4: SubCategoryDataAl[1].subtitle6subname5,
      navbar1title6subname5: SubCategoryDataAl[1].subtitle6subname6,
      navbar1title6subname6: SubCategoryDataAl[1].subtitle6subname7,
      navbar1title6subname7: SubCategoryDataAl[1].subtitle6subname8,
      navbar1title6subname8: SubCategoryDataAl[1].subtitle6subname9,
      navbar1title6subname9: SubCategoryDataAl[1].subtitle6subname10,
      navbar1title6subname10: SubCategoryDataAl[1].subtitle6subname11,
      navbar1title6subname11: SubCategoryDataAl[1].subtitle6subname12,
      navbar1title6subname12: SubCategoryDataAl[1].subtitle6subname13,
      navbar1title6subname13: SubCategoryDataAl[1].subtitle6subname14,
      navbar1title6subname14: SubCategoryDataAl[1].subtitle6subname15,
      navbar1title6subname15: SubCategoryDataAl[1].subtitle6subname16,
      navbar1title6subname16: SubCategoryDataAl[1].subtitle6subname17,
      navbar1title6subname17: SubCategoryDataAl[1].subtitle6subname18,
      navbar1title6subname18: SubCategoryDataAl[1].subtitle6subname19,
      navbar1title6subname19: SubCategoryDataAl[1].subtitle6subname20,

      navbar1title7subname: SubCategoryDataAl[1].subtitle7subname1,
      navbar1title7subname1: SubCategoryDataAl[1].subtitle7subname2,
      navbar1title7subname2: SubCategoryDataAl[1].subtitle7subname3,

      usertitle: UserDataAl[0].title,
      usertitle1: UserDataAl[0].title1,
      usertitle2: UserDataAl[0].title2,
      usertitle3: UserDataAl[0].title3,
      usertitle4: UserDataAl[0].title4,
      usertitle5: UserDataAl[0].title5,
      usertitle6: UserDataAl[0].title6,
      usertitle7: UserDataAl[0].title7,
      usertitle8: UserDataAl[0].title8,
      usertitle9: UserDataAl[0].title9,
      usertitle10: UserDataAl[0].title10,
      usertitle11: UserDataAl[0].title11,
      usertitle12: UserDataAl[0].title12,
      usertitle13: UserDataAl[0].title13,
      usertitle14: UserDataAl[0].title14,
      usertitle15: UserDataAl[0].title15,
      usertitle16: UserDataAl[0].title16,
      usertitle17: UserDataAl[0].title17,
      useraddress: UserDataAl[0].address,
      useraddress1: UserDataAl[0].address1,
      useraddress2: UserDataAl[0].address2,
      useraddress3: UserDataAl[0].address3,
      useraddress4: UserDataAl[0].address4,
      useraddress5: UserDataAl[0].address5,
      useraddress6: UserDataAl[0].address6,
      useraddress7: UserDataAl[0].address7,
      useraddress8: UserDataAl[0].address8,
      useraddress9: UserDataAl[0].address9,
      useraddress10: UserDataAl[0].address10,
      useraddress11: UserDataAl[0].address11,
      useraddress12: UserDataAl[0].address12,
      useraddress13: UserDataAl[0].address13,
      useraddress14: UserDataAl[0].address14,
      useraddress15: UserDataAl[0].address15,
      userfav: UserDataAl[0].fav,
      userproduct: UserDataAl[0].product,
      userproduct1: UserDataAl[0].product1,
      userproduct2: UserDataAl[0].product2,
      userproduc3: UserDataAl[0].product3,
      userproduct4: UserDataAl[0].product4,
      productdetailtitle: ProductDetailDataAl[0].title,
      productdetaildesc: ProductDetailDataAl[0].desc,
      productdetaildesc1: ProductDetailDataAl[0].desc1,
      productdetailtitle1: ProductDetailDataAl[0].title1,
      productdetailtitle2: ProductDetailDataAl[0].title2,
      productdetailtitle3: ProductDetailDataAl[0].title3,
      productdetailtitle4: ProductDetailDataAl[0].title4,
      productdetailtitle5: ProductDetailDataAl[0].title5,
      productdetailtitle6: ProductDetailDataAl[0].title6,
      productdetailtitle7: ProductDetailDataAl[0].title7,
      productdetailtitle8: ProductDetailDataAl[0].title8,
      productdetailtitle9: ProductDetailDataAl[0].title9,
      productdetailtitle10: ProductDetailDataAl[0].title10,
      productdetailtitle11: ProductDetailDataAl[0].title11,
      productdetailtitle12: ProductDetailDataAl[0].title12,
      productdetailtitle13: ProductDetailDataAl[0].title13,
      productdetailbutton: ProductDetailDataAl[0].button,
      pay: PayDataAL[0].tabbar,
      paytitle: PayDataAL[0].title,
      paydesc: PayDataAL[0].desc,
      paydesc1: PayDataAL[0].desc1,
      paydesc2: PayDataAL[0].desc2,
      payinput: PayDataAL[0].inputtitle,
      payinput1: PayDataAL[0].inputtitle1,
      payinput2: PayDataAL[0].inputtitle2,
      payinput3: PayDataAL[0].inputtitle3,
      payinput4: PayDataAL[0].inputtitle4,
      paycheck: PayDataAL[0].inputcheck,
      paytitle1: PayDataAL[0].title1,
      payinputcheck: PayDataAL[0].input2check,
      pay1input: PayDataAL[0].input1title,
      pay1input1: PayDataAL[0].input1title1,
      pay1input2: PayDataAL[0].input1title2,
      pay1input3: PayDataAL[0].input1title3,
      paytitle2: PayDataAL[0].title2,
      paycheck1: PayDataAL[0].check,
      paycheck2: PayDataAL[0].check1,
      paycheck3: PayDataAL[0].check2,
      paytitle3: PayDataAL[0].title3,
      paycheck4: PayDataAL[0].check4,
      paycheck5: PayDataAL[0].check5,
      pay2input: PayDataAL[0].input2title,
      pay2input1: PayDataAL[0].input2title1,
      pay2input2: PayDataAL[0].input2title2,
      pay2input3: PayDataAL[0].input2title3,
      paytitle4: PayDataAL[0].title4,
      category: CategoryDataAl[0].tabbar,
      category1: CategoryDataAl[0].tabbar2,
      categorysort: CategoryDataAl[0].sortelement,
      categorysort1: CategoryDataAl[0].sortelement1,
      categorysort2: CategoryDataAl[0].sortelement2,
      categorysort3: CategoryDataAl[0].sortelement3,
      categorysort4: CategoryDataAl[0].sortelement4,
      categorysorttrend: CategoryDataAl[0].sortelementend,
      categoryfilter: CategoryDataAl[0].filtertitle,
      categoryfilter1: CategoryDataAl[0].filtertitle1,
      categoryfilter2: CategoryDataAl[0].filtertitle2,
      categoryfilter3: CategoryDataAl[0].filtertitle3,
      categoryfilter4: CategoryDataAl[0].filtertitle4,
      categoryfilterbutton: CategoryDataAl[0].filterbutton,
      basket: BasketAl[0].title,
      basket1: BasketAl[0].title1,
      basket2: BasketAl[0].title2,
      basketpay: BasketAl[0].allpay,
      basketbutton: BasketAl[0].button,
      basketdetail: BasketAl[0].changebuttontitle,
      basketdetail1: BasketAl[0].changebuttontitle1,
      basketbuttonall: BasketAl[0].buttonall,
      basketform: BasketAl[0].form,
      basketformdetail: BasketAl[0].formcountry,
      basketformdetailcity: BasketAl[0].formcity,
      basketformdetailzip: BasketAl[0].formpostcode,
      basketform2: BasketAl[0].form2,
      basketformdesc: BasketAl[0].form2desc,
      basketforminput: BasketAl[0].forminput,
      basketformbutton: BasketAl[0].formbutton,
      basketform3: BasketAl[0].form3,
      basketmodal: BasketModalAl[0].title,
      basketmodal1: BasketModalAl[0].title2,
      basketmodaltotal: BasketModalAl[0].footerpay,
      basketmodalbutton: BasketModalAl[0].gobasket,
      basketmodalbutton1: BasketModalAl[0].buy,
      loginmodal: LoginModalAl[0].title,
      loginmodal2: LoginModalAl[0].title1,
      loginmodal3: LoginModalAl[0].title2,
      loginmodalbutton: LoginModalAl[0].button,
      loginmodaldetail: LoginModalAl[0].detail,
      loginmodaldetailmain: LoginModalAl[0].detailmain,
      passtab: PassAl[0].tabbar,
      passtab1: PassAl[0].tabbar2,
      passdesc: PassAl[0].desc,
      passbutton: PassAl[0].button,
      padddetail: PassAl[0].detail,
      padddetaildesc: PassAl[0].detaildesc,
      padddetailtitle: PassAl[0].detailtitle,
      padddetaillist: PassAl[0].detaillist,
      padddetaillist1: PassAl[0].detaillist1,
      padddetaillist2: PassAl[0].detaillist2,
      padddetailbutton: PassAl[0].detailbutton,
      padddetailbutton1: PassAl[0].detailbutton1,
      authsign: SigninAl[0].tabbar,
      authsign2: SigninAl[0].tabbar2,
      authsigndesc: SigninAl[0].desc,
      authsigntitle: SigninAl[0].title,
      authsigntitle1: SigninAl[0].title1,
      authsigntitle2: SigninAl[0].title2,
      authsigntitle3: SigninAl[0].title3,
      authsigntitle4: SigninAl[0].title4,
      authsigncheck: SigninAl[0].check,
      authsignbutton: SigninAl[0].button,
      authtab: LoginAl[0].tabbar,
      authtab2: LoginAl[0].tabbar2,
      authlogin: LoginAl[0].title,
      authlogindesc: LoginAl[0].desc,
      authtitle: LoginAl[0].title,
      authtitle2: LoginAl[0].title2,
      autcheck: LoginAl[0].check,
      autpass: LoginAl[0].pass,
      authbutton: LoginAl[0].button,
      autsubdesc: LoginAl[0].subdesc,
      header: LayoutDataAl[0].header,
      header1: LayoutDataAl[0].header1,
      header2: LayoutDataAl[0].header2,
      homename: LayoutDataAl[0].title,
      homename2: LayoutDataAl[0].title2,
      homedesc: LayoutDataAl[0].title3,
      homecategory: LayoutDataAl[0].categorytitle,
      homecategory2: LayoutDataAl[0].categorytitle2,
      homecategorydesc: LayoutDataAl[0].categorydesc,
      homecategorydesc2: LayoutDataAl[0].categorydesc2,
      homeslider: LayoutDataAl[0].slider,
      homesliderdesc: LayoutDataAl[0].sliderdesc,
      homesliderbutton: LayoutDataAl[0].sliderbutton,
      homebanner: LayoutDataAl[0].banner,
      homebannerdesc: LayoutDataAl[0].bannerdesc,
      homebannerbutton: LayoutDataAl[0].bannerbutton,
      tabtitle: FooterTabDataAl[0].title,
      tabtitle2: FooterTabDataAl[0].title1,
      tabtitle3: FooterTabDataAl[0].title2,
      tabdesc: FooterTabDataAl[0].desc,
      tabdesc1: FooterTabDataAl[0].desc1,
      tabdesc2: FooterTabDataAl[0].desc2,
      tabnamemain: FooterTabDataAl[0].titlemain,
      tabdescmain: FooterTabDataAl[0].titlemaindesc,
      tabbottomtitle: FooterTabDataAl[0].bottomtitle,
      tabbutton: FooterTabDataAl[0].mainbutton,
      name: FooterDataAl[0].name,
      mail: FooterDataAl[0].mail,
      time: FooterDataAl[0].time,
      footer: FooterDataAl[0].footer,
      subname: FooterDataAl[1].name,
      subtitle: FooterDataAl[1].title[0].subname,
      subtitle1: FooterDataAl[1].title[1].subname,
      subtitle2: FooterDataAl[1].title[2].subname,
      subtitle3: FooterDataAl[1].title[3].subname,
      subtitle4: FooterDataAl[1].title[4].subname,
      subname2: FooterDataAl[2].name,
      sub2title: FooterDataAl[2].title[0].subname,
      sub2title1: FooterDataAl[2].title[1].subname,
      sub2title2: FooterDataAl[2].title[2].subname,
      sub2title3: FooterDataAl[2].title[3].subname,
      sub2title4: FooterDataAl[2].title[4].subname,
      subname3: FooterDataAl[3].name,
      sub3title: FooterDataAl[3].title[0].subname,
      sub3title1: FooterDataAl[3].title[1].subname,
      sub3title2: FooterDataAl[3].title[2].subname,
    },
  },
  mk: {
    translation: {
      navbar: SubCategoryDataMk[0].title,
      navbartitle: SubCategoryDataMk[0].subtitle,
      navbartitle1: SubCategoryDataMk[0].subtitle1,
      navbartitle2: SubCategoryDataMk[0].subtitle2,
      navbartitle3: SubCategoryDataMk[0].subtitle3,
      navbartitle4: SubCategoryDataMk[0].subtitle4,
      navbartitle5: SubCategoryDataMk[0].subtitle5,
      navbartitle6: SubCategoryDataMk[0].subtitle6,
      navbartitlesubname: SubCategoryDataMk[0].subtitlesubname1,
      navbartitlesubname1: SubCategoryDataMk[0].subtitlesubname2,
      navbartitlesubname2: SubCategoryDataMk[0].subtitlesubname3,
      navbartitlesubname3: SubCategoryDataMk[0].subtitlesubname4,
      navbartitlesubname4: SubCategoryDataMk[0].subtitlesubname5,
      navbartitlesubname5: SubCategoryDataMk[0].subtitlesubname6,
      navbartitlesubname6: SubCategoryDataMk[0].subtitlesubname7,
      navbartitlesubname7: SubCategoryDataMk[0].subtitlesubname8,
      navbartitlesubname8: SubCategoryDataMk[0].subtitlesubname9,
      navbartitlesubname9: SubCategoryDataMk[0].subtitlesubname10,
      navbartitlesubname10: SubCategoryDataMk[0].subtitlesubname11,
      navbartitlesubname11: SubCategoryDataMk[0].subtitlesubname12,
      navbartitlesubname12: SubCategoryDataMk[0].subtitlesubname13,
      navbartitlesubname13: SubCategoryDataMk[0].subtitlesubname14,
      navbartitlesubname14: SubCategoryDataMk[0].subtitlesubname15,
      navbartitlesubname15: SubCategoryDataMk[0].subtitlesubname16,
      navbartitlesubname16: SubCategoryDataMk[0].subtitlesubname17,
      navbartitlesubname17: SubCategoryDataMk[0].subtitlesubname18,
      navbartitlesubname18: SubCategoryDataMk[0].subtitlesubname19,

      navbartitle1subname: SubCategoryDataMk[0].subtitle1subname1,
      navbartitle1subname1: SubCategoryDataMk[0].subtitle1subname2,
      navbartitle1subname2: SubCategoryDataMk[0].subtitle1subname3,
      navbartitle1subname3: SubCategoryDataMk[0].subtitle1subname4,
      navbartitle1subname4: SubCategoryDataMk[0].subtitle1subname5,
      navbartitle1subname5: SubCategoryDataMk[0].subtitle1subname6,
      navbartitle1subname6: SubCategoryDataMk[0].subtitle1subname7,
      navbartitle1subname7: SubCategoryDataMk[0].subtitle1subname8,

      navbartitle2subname: SubCategoryDataMk[0].subtitle2subname1,
      navbartitle2subname1: SubCategoryDataMk[0].subtitle2subname2,
      navbartitle2subname2: SubCategoryDataMk[0].subtitle2subname3,
      navbartitle2subname3: SubCategoryDataMk[0].subtitle2subname4,
      navbartitle2subname4: SubCategoryDataMk[0].subtitle2subname5,
      navbartitle2subname5: SubCategoryDataMk[0].subtitle2subname6,
      navbartitle2subname6: SubCategoryDataMk[0].subtitle2subname7,
      navbartitle2subname7: SubCategoryDataMk[0].subtitle2subname8,
      navbartitle2subname8: SubCategoryDataMk[0].subtitle2subname9,
      navbartitle2subname9: SubCategoryDataMk[0].subtitle2subname10,
      navbartitle2subname10: SubCategoryDataMk[0].subtitle2subname11,

      navbartitle3subname: SubCategoryDataMk[0].subtitle3subname1,
      navbartitle3subname1: SubCategoryDataMk[0].subtitle3subname2,
      navbartitle3subname2: SubCategoryDataMk[0].subtitle3subname3,
      navbartitle3subname3: SubCategoryDataMk[0].subtitle3subname4,
      navbartitle3subname4: SubCategoryDataMk[0].subtitle3subname5,
      navbartitle3subname5: SubCategoryDataMk[0].subtitle3subname6,
      navbartitle3subname6: SubCategoryDataMk[0].subtitle3subname7,
      navbartitle3subname7: SubCategoryDataMk[0].subtitle3subname8,
      navbartitle3subname8: SubCategoryDataMk[0].subtitle3subname9,
      navbartitle3subname9: SubCategoryDataMk[0].subtitle3subname10,
      navbartitle3subname10: SubCategoryDataMk[0].subtitle3subname11,
      navbartitle3subname11: SubCategoryDataMk[0].subtitle3subname12,
      navbartitle3subname12: SubCategoryDataMk[0].subtitle3subname13,
      navbartitle3subname13: SubCategoryDataMk[0].subtitle3subname14,
      navbartitle3subname14: SubCategoryDataMk[0].subtitle3subname15,
      navbartitle3subname15: SubCategoryDataMk[0].subtitle3subname16,
      navbartitle3subname16: SubCategoryDataMk[0].subtitle3subname17,
      navbartitle3subname17: SubCategoryDataMk[0].subtitle3subname18,
      navbartitle3subname18: SubCategoryDataMk[0].subtitle3subname19,
      navbartitle3subname19: SubCategoryDataMk[0].subtitle3subname20,

      navbartitle4subname: SubCategoryDataMk[0].subtitle4subname1,
      navbartitle4subname1: SubCategoryDataMk[0].subtitle4subname2,
      navbartitle4subname2: SubCategoryDataMk[0].subtitle4subname3,
      navbartitle4subname3: SubCategoryDataMk[0].subtitle4subname4,
      navbartitle4subname4: SubCategoryDataMk[0].subtitle4subname5,
      navbartitle4subname5: SubCategoryDataMk[0].subtitle4subname6,
      navbartitle4subname6: SubCategoryDataMk[0].subtitle4subname7,
      navbartitle4subname7: SubCategoryDataMk[0].subtitle4subname8,
      navbartitle4subname8: SubCategoryDataMk[0].subtitle4subname9,
      navbartitle4subname9: SubCategoryDataMk[0].subtitle4subname10,
      navbartitle4subname10: SubCategoryDataMk[0].subtitle4subname11,
      navbartitle4subname11: SubCategoryDataMk[0].subtitle4subname12,
      navbartitle4subname12: SubCategoryDataMk[0].subtitle4subname13,

      navbartitle5subname: SubCategoryDataMk[0].subtitle5subname1,
      navbartitle5subname1: SubCategoryDataMk[0].subtitle5subname2,
      navbartitle5subname2: SubCategoryDataMk[0].subtitle5subname3,
      navbartitle5subname3: SubCategoryDataMk[0].subtitle5subname4,
      navbartitle5subname4: SubCategoryDataMk[0].subtitle5subname5,

      navbartitle6subname1: SubCategoryDataMk[0].subtitle6subname2,
      navbartitle6subname2: SubCategoryDataMk[0].subtitle6subname3,
      navbartitle6subname3: SubCategoryDataMk[0].subtitle6subname4,
      navbartitle6subname4: SubCategoryDataMk[0].subtitle6subname5,
      navbartitle6subname5: SubCategoryDataMk[0].subtitle6subname6,
      navbartitle6subname6: SubCategoryDataMk[0].subtitle6subname7,
      navbartitle6subname7: SubCategoryDataMk[0].subtitle6subname8,
      navbartitle6subname8: SubCategoryDataMk[0].subtitle6subname9,
      navbartitle6subname9: SubCategoryDataMk[0].subtitle6subname10,
      navbartitle6subname10: SubCategoryDataMk[0].subtitle6subname11,
      navbartitle6subname11: SubCategoryDataMk[0].subtitle6subname12,
      navbartitle6subname12: SubCategoryDataMk[0].subtitle6subname13,
      navbartitle6subname13: SubCategoryDataMk[0].subtitle6subname14,
      navbartitle6subname14: SubCategoryDataMk[0].subtitle6subname15,
      navbartitle6subname15: SubCategoryDataMk[0].subtitle6subname16,
      navbartitle6subname16: SubCategoryDataMk[0].subtitle6subname17,
      navbartitle6subname17: SubCategoryDataMk[0].subtitle6subname18,
      navbartitle6subname18: SubCategoryDataMk[0].subtitle6subname19,
      navbartitle6subname19: SubCategoryDataMk[0].subtitle6subname20,

      navbar1: SubCategoryDataMk[1].title,
      navbar1title: SubCategoryDataMk[1].subtitle,
      navbar1title1: SubCategoryDataMk[1].subtitle1,
      navbar1title2: SubCategoryDataMk[1].subtitle2,
      navbar1title3: SubCategoryDataMk[1].subtitle3,
      navbar1title4: SubCategoryDataMk[1].subtitle4,
      navbar1title5: SubCategoryDataMk[1].subtitle5,
      navbar1title6: SubCategoryDataMk[1].subtitle6,
      navbar1title7: SubCategoryDataMk[1].subtitle7,

      navbar1titlesubname: SubCategoryDataMk[1].subtitlesubname1,
      navbar1titlesubname1: SubCategoryDataMk[1].subtitlesubname2,
      navbar1titlesubname2: SubCategoryDataMk[1].subtitlesubname3,
      navbar1titlesubname3: SubCategoryDataMk[1].subtitlesubname4,
      navbar1titlesubname4: SubCategoryDataMk[1].subtitlesubname5,
      navbar1titlesubname5: SubCategoryDataMk[1].subtitlesubname6,
      navbar1titlesubname6: SubCategoryDataMk[1].subtitlesubname7,
      navbar1titlesubname7: SubCategoryDataMk[1].subtitlesubname8,
      navbar1titlesubname8: SubCategoryDataMk[1].subtitlesubname9,
      navbar1titlesubname9: SubCategoryDataMk[1].subtitlesubname10,
      navbar1titlesubname10: SubCategoryDataMk[1].subtitlesubname11,
      navbar1titlesubname11: SubCategoryDataMk[1].subtitlesubname12,
      navbar1titlesubname12: SubCategoryDataMk[1].subtitlesubname13,
      navbar1titlesubname13: SubCategoryDataMk[1].subtitlesubname14,
      navbar1titlesubname14: SubCategoryDataMk[1].subtitlesubname15,
      navbar1titlesubname15: SubCategoryDataMk[1].subtitlesubname16,
      navbar1titlesubname16: SubCategoryDataMk[1].subtitlesubname17,
      navbar1titlesubname17: SubCategoryDataMk[1].subtitlesubname18,
      navbar1titlesubname18: SubCategoryDataMk[1].subtitlesubname19,
      navbar1titlesubname19: SubCategoryDataMk[1].subtitlesubname20,

      navbar1title1subname: SubCategoryDataMk[1].subtitle1subname1,
      navbar1title1subname1: SubCategoryDataMk[1].subtitle1subname2,
      navbar1title1subname2: SubCategoryDataMk[1].subtitle1subname3,
      navbar1title1subname3: SubCategoryDataMk[1].subtitle1subname4,
      navbar1title1subname4: SubCategoryDataMk[1].subtitle1subname5,
      navbar1title1subname5: SubCategoryDataMk[1].subtitle1subname6,
      navbar1title1subname6: SubCategoryDataMk[1].subtitle1subname7,
      navbar1title1subname7: SubCategoryDataMk[1].subtitle1subname8,
      navbar1title1subname8: SubCategoryDataMk[1].subtitle1subname9,
      navbar1title1subname9: SubCategoryDataMk[1].subtitle1subname10,
      navbar1title1subname10: SubCategoryDataMk[1].subtitle1subname11,
      navbar1title1subname11: SubCategoryDataMk[1].subtitle1subname12,
      navbar1title1subname12: SubCategoryDataMk[1].subtitle1subname13,

      navbar1title2subname: SubCategoryDataMk[1].subtitle2subname1,
      navbar1title2subname1: SubCategoryDataMk[1].subtitle2subname2,
      navbar1title2subname2: SubCategoryDataMk[1].subtitle2subname3,
      navbar1title2subname3: SubCategoryDataMk[1].subtitle2subname4,
      navbar1title2subname4: SubCategoryDataMk[1].subtitle2subname5,
      navbar1title2subname5: SubCategoryDataMk[1].subtitle2subname6,
      navbar1title2subname6: SubCategoryDataMk[1].subtitle2subname7,
      navbar1title2subname7: SubCategoryDataMk[1].subtitle2subname8,
      navbar1title2subname8: SubCategoryDataMk[1].subtitle2subname9,

      navbar1title3subname: SubCategoryDataMk[1].subtitle3subname1,
      navbar1title3subname1: SubCategoryDataMk[1].subtitle3subname2,
      navbar1title3subname2: SubCategoryDataMk[1].subtitle3subname3,
      navbar1title3subname3: SubCategoryDataMk[1].subtitle3subname4,
      navbar1title3subname4: SubCategoryDataMk[1].subtitle3subname5,
      navbar1title3subname5: SubCategoryDataMk[1].subtitle3subname6,
      navbar1title3subname6: SubCategoryDataMk[1].subtitle3subname7,
      navbar1title3subname7: SubCategoryDataMk[1].subtitle3subname8,
      navbar1title3subname8: SubCategoryDataMk[1].subtitle3subname9,

      navbar1title4subname: SubCategoryDataMk[1].subtitle4subname1,
      navbar1title4subname1: SubCategoryDataMk[1].subtitle4subname2,
      navbar1title4subname2: SubCategoryDataMk[1].subtitle4subname3,
      navbar1title4subname3: SubCategoryDataMk[1].subtitle4subname4,
      navbar1title4subname4: SubCategoryDataMk[1].subtitle4subname5,
      navbar1title4subname5: SubCategoryDataMk[1].subtitle4subname6,
      navbar1title4subname6: SubCategoryDataMk[1].subtitle4subname7,
      navbar1title4subname7: SubCategoryDataMk[1].subtitle4subname8,
      navbar1title4subname8: SubCategoryDataMk[1].subtitle4subname9,
      navbar1title4subname9: SubCategoryDataMk[1].subtitle4subname10,
      navbar1title4subname10: SubCategoryDataMk[1].subtitle4subname11,
      navbar1title4subname11: SubCategoryDataMk[1].subtitle4subname12,
      navbar1title4subname12: SubCategoryDataMk[1].subtitle4subname13,

      navbar1title5subname: SubCategoryDataMk[1].subtitle5subname1,
      navbar1title5subname1: SubCategoryDataMk[1].subtitle5subname2,
      navbar1title5subname2: SubCategoryDataMk[1].subtitle5subname3,
      navbar1title5subname3: SubCategoryDataMk[1].subtitle5subname4,
      navbar1title5subname4: SubCategoryDataMk[1].subtitle5subname5,

      navbar1title6subname1: SubCategoryDataMk[1].subtitle6subname2,
      navbar1title6subname2: SubCategoryDataMk[1].subtitle6subname3,
      navbar1title6subname3: SubCategoryDataMk[1].subtitle6subname4,
      navbar1title6subname4: SubCategoryDataMk[1].subtitle6subname5,
      navbar1title6subname5: SubCategoryDataMk[1].subtitle6subname6,
      navbar1title6subname6: SubCategoryDataMk[1].subtitle6subname7,
      navbar1title6subname7: SubCategoryDataMk[1].subtitle6subname8,
      navbar1title6subname8: SubCategoryDataMk[1].subtitle6subname9,
      navbar1title6subname9: SubCategoryDataMk[1].subtitle6subname10,
      navbar1title6subname10: SubCategoryDataMk[1].subtitle6subname11,
      navbar1title6subname11: SubCategoryDataMk[1].subtitle6subname12,
      navbar1title6subname12: SubCategoryDataMk[1].subtitle6subname13,
      navbar1title6subname13: SubCategoryDataMk[1].subtitle6subname14,
      navbar1title6subname14: SubCategoryDataMk[1].subtitle6subname15,
      navbar1title6subname15: SubCategoryDataMk[1].subtitle6subname16,
      navbar1title6subname16: SubCategoryDataMk[1].subtitle6subname17,
      navbar1title6subname17: SubCategoryDataMk[1].subtitle6subname18,
      navbar1title6subname18: SubCategoryDataMk[1].subtitle6subname19,
      navbar1title6subname19: SubCategoryDataMk[1].subtitle6subname20,

      navbar1title7subname: SubCategoryDataMk[1].subtitle7subname1,
      navbar1title7subname1: SubCategoryDataMk[1].subtitle7subname2,
      navbar1title7subname2: SubCategoryDataMk[1].subtitle7subname3,

      usertitle: UserDataMk[0].title,
      usertitle1: UserDataMk[0].title1,
      usertitle2: UserDataMk[0].title2,
      usertitle3: UserDataMk[0].title3,
      usertitle4: UserDataMk[0].title4,
      usertitle5: UserDataMk[0].title5,
      usertitle6: UserDataMk[0].title6,
      usertitle7: UserDataMk[0].title7,
      usertitle8: UserDataMk[0].title8,
      usertitle9: UserDataMk[0].title9,
      usertitle10: UserDataMk[0].title10,
      usertitle11: UserDataMk[0].title11,
      usertitle12: UserDataMk[0].title12,
      usertitle13: UserDataMk[0].title13,
      usertitle14: UserDataMk[0].title14,
      usertitle15: UserDataMk[0].title15,
      usertitle16: UserDataMk[0].title16,
      usertitle17: UserDataMk[0].title17,
      useraddress: UserDataMk[0].address,
      useraddress1: UserDataMk[0].address1,
      useraddress2: UserDataMk[0].address2,
      useraddress3: UserDataMk[0].address3,
      useraddress4: UserDataMk[0].address4,
      useraddress5: UserDataMk[0].address5,
      useraddress6: UserDataMk[0].address6,
      useraddress7: UserDataMk[0].address7,
      useraddress8: UserDataMk[0].address8,
      useraddress9: UserDataMk[0].address9,
      useraddress10: UserDataMk[0].address10,
      useraddress11: UserDataMk[0].address11,
      useraddress12: UserDataMk[0].address12,
      useraddress13: UserDataMk[0].address13,
      useraddress14: UserDataMk[0].address14,
      useraddress15: UserDataMk[0].address15,
      userfav: UserDataMk[0].fav,
      userproduct: UserDataMk[0].product,
      userproduct1: UserDataMk[0].product1,
      userproduct2: UserDataMk[0].product2,
      userproduc3: UserDataMk[0].product3,
      userproduct4: UserDataMk[0].product4,
      productdetailtitle: ProductDetailDataMk[0].title,
      productdetaildesc: ProductDetailDataMk[0].desc,
      productdetaildesc1: ProductDetailDataMk[0].desc1,
      productdetailtitle1: ProductDetailDataMk[0].title1,
      productdetailtitle2: ProductDetailDataMk[0].title2,
      productdetailtitle3: ProductDetailDataMk[0].title3,
      productdetailtitle4: ProductDetailDataMk[0].title4,
      productdetailtitle5: ProductDetailDataMk[0].title5,
      productdetailtitle6: ProductDetailDataMk[0].title6,
      productdetailtitle7: ProductDetailDataMk[0].title7,
      productdetailtitle8: ProductDetailDataMk[0].title8,
      productdetailtitle9: ProductDetailDataMk[0].title9,
      productdetailtitle10: ProductDetailDataMk[0].title10,
      productdetailtitle11: ProductDetailDataMk[0].title11,
      productdetailtitle12: ProductDetailDataMk[0].title12,
      productdetailtitle13: ProductDetailDataMk[0].title13,
      productdetailbutton: ProductDetailDataMk[0].button,
      pay: PayDataMk[0].tabbar,
      paytitle: PayDataMk[0].title,
      paydesc: PayDataMk[0].desc,
      paydesc1: PayDataMk[0].desc1,
      paydesc2: PayDataMk[0].desc2,
      payinput: PayDataMk[0].inputtitle,
      payinput1: PayDataMk[0].inputtitle1,
      payinput2: PayDataMk[0].inputtitle2,
      payinput3: PayDataMk[0].inputtitle3,
      payinput4: PayDataMk[0].inputtitle4,
      paycheck: PayDataMk[0].inputcheck,
      paytitle1: PayDataMk[0].title1,
      payinputcheck: PayDataMk[0].input2check,
      pay1input: PayDataMk[0].input1title,
      pay1input1: PayDataMk[0].input1title1,
      pay1input2: PayDataMk[0].input1title2,
      pay1input3: PayDataMk[0].input1title3,
      paytitle2: PayDataMk[0].title2,
      paycheck1: PayDataMk[0].check,
      paycheck2: PayDataMk[0].check1,
      paycheck3: PayDataMk[0].check2,
      paytitle3: PayDataMk[0].title3,
      paycheck4: PayDataMk[0].check4,
      paycheck5: PayDataMk[0].check5,
      pay2input: PayDataMk[0].input2title,
      pay2input1: PayDataMk[0].input2title1,
      pay2input2: PayDataMk[0].input2title2,
      pay2input3: PayDataMk[0].input2title3,
      paytitle4: PayDataMk[0].title4,
      category: CategoryDataMk[0].tabbar,
      category1: CategoryDataMk[0].tabbar2,
      categorysort: CategoryDataMk[0].sortelement,
      categorysort1: CategoryDataMk[0].sortelement1,
      categorysort2: CategoryDataMk[0].sortelement2,
      categorysort3: CategoryDataMk[0].sortelement3,
      categorysort4: CategoryDataMk[0].sortelement4,
      categorysorttrend: CategoryDataMk[0].sortelementend,
      categoryfilter: CategoryDataMk[0].filtertitle,
      categoryfilter1: CategoryDataMk[0].filtertitle1,
      categoryfilter2: CategoryDataMk[0].filtertitle2,
      categoryfilter3: CategoryDataMk[0].filtertitle3,
      categoryfilter4: CategoryDataMk[0].filtertitle4,
      categoryfilterbutton: CategoryDataMk[0].filterbutton,
      basket: BasketMk[0].title,
      basket1: BasketMk[0].title1,
      basket2: BasketMk[0].title2,
      basketpay: BasketMk[0].allpay,
      basketbutton: BasketMk[0].button,
      basketdetail: BasketMk[0].changebuttontitle,
      basketdetail1: BasketMk[0].changebuttontitle1,
      basketbuttonall: BasketMk[0].buttonall,
      basketform: BasketMk[0].form,
      basketformdetail: BasketMk[0].formcountry,
      basketformdetailcity: BasketMk[0].formcity,
      basketformdetailzip: BasketMk[0].formpostcode,
      basketform2: BasketMk[0].form2,
      basketformdesc: BasketMk[0].form2desc,
      basketforminput: BasketMk[0].forminput,
      basketformbutton: BasketMk[0].formbutton,
      basketform3: BasketMk[0].form3,
      basketmodal: BasketModalMk[0].title,
      basketmodal1: BasketModalMk[0].title2,
      basketmodaltotal: BasketModalMk[0].footerpay,
      basketmodalbutton: BasketModalMk[0].gobasket,
      basketmodalbutton1: BasketModalMk[0].buy,
      loginmodal: LoginModalMk[0].title,
      loginmodal2: LoginModalMk[0].title1,
      loginmodal3: LoginModalMk[0].title2,
      loginmodalbutton: LoginModalMk[0].button,
      loginmodaldetail: LoginModalMk[0].detail,
      loginmodaldetailmain: LoginModalMk[0].detailmain,
      passtab: PassMk[0].tabbar,
      passtab1: PassMk[0].tabbar2,
      passdesc: PassMk[0].desc,
      passbutton: PassMk[0].button,
      padddetail: PassMk[0].detail,
      padddetaildesc: PassMk[0].detaildesc,
      padddetailtitle: PassMk[0].detailtitle,
      padddetaillist: PassMk[0].detaillist,
      padddetaillist1: PassMk[0].detaillist1,
      padddetaillist2: PassMk[0].detaillist2,
      padddetailbutton: PassMk[0].detailbutton,
      padddetailbutton1: PassMk[0].detailbutton1,
      authsign: SigninMk[0].tabbar,
      authsign2: SigninMk[0].tabbar2,
      authsigndesc: SigninMk[0].desc,
      authsigntitle: SigninMk[0].title,
      authsigntitle1: SigninMk[0].title1,
      authsigntitle2: SigninMk[0].title2,
      authsigntitle3: SigninMk[0].title3,
      authsigntitle4: SigninMk[0].title4,
      authsigncheck: SigninMk[0].check,
      authsignbutton: SigninMk[0].button,
      authtab: LoginMk[0].tabbar,
      authtab2: LoginMk[0].tabbar2,
      authlogin: LoginMk[0].title,
      authlogindesc: LoginMk[0].desc,
      authtitle: LoginMk[0].title,
      authtitle2: LoginMk[0].title2,
      autcheck: LoginMk[0].check,
      autpass: LoginMk[0].pass,
      authbutton: LoginMk[0].button,
      autsubdesc: LoginMk[0].subdesc,
      header: LayoutDataMk[0].header,
      header1: LayoutDataMk[0].header1,
      header2: LayoutDataMk[0].header2,
      homename: LayoutDataMk[0].title,
      homename2: LayoutDataMk[0].title2,
      homedesc: LayoutDataMk[0].title3,
      homecategory: LayoutDataMk[0].categorytitle,
      homecategory2: LayoutDataMk[0].categorytitle2,
      homecategorydesc: LayoutDataMk[0].categorydesc,
      homecategorydesc2: LayoutDataMk[0].categorydesc2,
      homeslider: LayoutDataMk[0].slider,
      homesliderdesc: LayoutDataMk[0].sliderdesc,
      homesliderbutton: LayoutDataMk[0].sliderbutton,
      homebanner: LayoutDataMk[0].banner,
      homebannerdesc: LayoutDataMk[0].bannerdesc,
      homebannerbutton: LayoutDataMk[0].bannerbutton,
      tabtitle: FooterTabDataMk[0].title,
      tabtitle2: FooterTabDataMk[0].title1,
      tabtitle3: FooterTabDataMk[0].title2,
      tabdesc: FooterTabDataMk[0].desc,
      tabdesc1: FooterTabDataMk[0].desc1,
      tabdesc2: FooterTabDataMk[0].desc2,
      tabnamemain: FooterTabDataMk[0].titlemain,
      tabdescmain: FooterTabDataMk[0].titlemaindesc,
      tabbottomtitle: FooterTabDataMk[0].bottomtitle,
      tabbutton: FooterTabDataMk[0].mainbutton,
      name: FooterDataMk[0].name,
      mail: FooterDataMk[0].mail,
      time: FooterDataEn[0].time,
      footer: FooterDataMk[0].footer,
      subname: FooterDataMk[1].name,
      subtitle: FooterDataMk[1].title[0].subname,
      subtitle1: FooterDataMk[1].title[1].subname,
      subtitle2: FooterDataMk[1].title[2].subname,
      subtitle3: FooterDataMk[1].title[3].subname,
      subtitle4: FooterDataMk[1].title[4].subname,
      subname2: FooterDataMk[2].name,
      sub2title: FooterDataMk[2].title[0].subname,
      sub2title1: FooterDataMk[2].title[1].subname,
      sub2title2: FooterDataMk[2].title[2].subname,
      sub2title3: FooterDataMk[2].title[3].subname,
      sub2title4: FooterDataMk[2].title[4].subname,
      subname3: FooterDataMk[3].name,
      sub3title: FooterDataMk[3].title[0].subname,
      sub3title1: FooterDataMk[3].title[1].subname,
      sub3title2: FooterDataMk[3].title[2].subname,
    },
  },
};

i18n.use(initReactI18next).init({
  lng: "en",
  resources,
});

const language = i18n.language || "en";
const homeDetail = language === "al" ? FooterDataAl : FooterDataEn;

export { homeDetail };
